import React, { FC, useEffect, useMemo, useState } from "react";
import { When } from "react-if";

import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Link,
  boxClasses,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import BeneficiaryServices from "services/beneficiaries.services";
import type { PaymentInfo } from "@lib/types/institutionPayment";
import type { BeneficiaryResponse } from "@lib/types/beneficiary";

export type EducationInstitution = {
  key: string;
  name: string;
};

const Content = styled(Stack)(({ theme }) => ({
  margin: "2.75rem 1.75rem 1.75rem 1.75rem",
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    margin: "2.75rem 0",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  width: "90%",
  textAlign: "justify",
  marginTop: "1rem",
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

const RadioControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: "18.75rem",
  height: "5rem",
  margin: ".625rem 0",
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: ".625rem",
  "& span.MuiFormControlLabel-label": {
    flexGrow: 1,
  },
  textAlign: "left"
}));

const StackLabel = styled(Stack)({
  justifyContent: "space-around",
  flexGrow: 1,
  [`& .${boxClasses.root}`]: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
  },
});

const CenteredFormControl = styled(FormControl)({
  alignItems: "center",
  minHeight: "5rem",
  justifyContent: "center",
});

function CustomLabel({ name, id }: { name: string; id: string }) {
  return (
    <StackLabel direction="row">
      <Box>
        <Typography sx={{ fontWeight: 700 }}>Name</Typography>
        <Typography>{name}</Typography>
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 700 }}>Student ID</Typography>
        <Typography>{id}</Typography>
      </Box>
    </StackLabel>
  );
}

interface BeneficiarySelectionProp {
  handleNext: () => void;
  updatePaymentInfo: (info: Partial<PaymentInfo>) => void;
}

const BeneficiarySelection: FC<BeneficiarySelectionProp> = ({ handleNext, updatePaymentInfo }) => {
  const [students, setStudents] = React.useState<BeneficiaryResponse[]>([]);
  const [selectedStudent, setSelectedStudent] = React.useState<BeneficiaryResponse | null>(null);
  const [selectedStudentId, setSelectedStudentId] = useState<string>("");

  const [error, setError] = React.useState("");
  const [studentIdInputError, setStudentIdInputError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const studentIdInputHelper = useMemo(() => {
    if (!studentIdInputError) return "Required to match the student's account";

    return studentIdInputError;
  }, [studentIdInputError]);

  // [SM 06/25/2024] - this is similar to what is being done in payment/index.tsx
  // we need to refactor this and in payment/index.tsx when there are multiple enrollments coming back from the API
  const enrollmentMissingStudentId = selectedStudent?.enrollments.find((enrollment) => !enrollment.student_id);

  useEffect(() => {
    setLoading(true);
    BeneficiaryServices.getBeneficiaries()
      .then((students) => {
        setStudents(() => students);
        return setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return setError(error);
      });
  }, []);

  useEffect(() => {
    if (students.length) setSelectedStudent(() => students[0]);
  }, [students]);

  const handleConfirmBeneficiary = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedStudent) {
      setError("Please select a student");
      return;
    }

    if (!!enrollmentMissingStudentId && !selectedStudentId) {
      setError("Please provide the student id");
      return;
    }

    let selectedStudentDetails = { ...selectedStudent };

    if (!!enrollmentMissingStudentId && !!selectedStudent) {
      try {
        setError("");
        setStudentIdInputError("");

        await BeneficiaryServices.updateBeneficiaryEnrollment({
          beneficiaryId: selectedStudent.id,
          enrollmentId: enrollmentMissingStudentId.id,
          payload: { student_id: selectedStudentId }
        });
      } catch (error: any) {
        if (error.type === "INVALID_FORMAT") {
          setStudentIdInputError(error.error_message);
          setError("There was a problem submitting the form. Fix the issues and try again.");
        } else {
          setError("An error occured, please try again or contact support.");
        }
        return;
      }
    }

    if (selectedStudentId) {
      selectedStudentDetails = {
        ...selectedStudent,
        enrollments: [{
          ...selectedStudent.enrollments[0],
          student_id: selectedStudentId
        }]
      };
    }

    updatePaymentInfo({ student: selectedStudentDetails });

    handleNext();
  };

  const handleStudentSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStudent((event.target as HTMLInputElement).value as unknown as BeneficiaryResponse);
  };

  return (
    <Content>
      <CenteredFormControl>
        {loading ? (
          <CircularProgress size="1.7rem" sx={{ color: "grey" }} />
        ) : (
          <form id="beneficiary-selection-form" onSubmit={handleConfirmBeneficiary}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={selectedStudent}
              onChange={handleStudentSelection}>
              {students.map((student: BeneficiaryResponse, index) => (
                <RadioControlLabel
                  data-cy={`beneficiary-selection-${index}`}
                  key={student.id}
                  value={student}
                  control={<Radio />}
                  label={
                    <CustomLabel
                      name={`${student.first_name} ${student.last_name}`}
                      id={student.enrollments[0].student_id || "No student ID"}
                    />
                  }
                />
              ))}
            </RadioGroup>

            <When condition={!!enrollmentMissingStudentId}>
              <TextField
                fullWidth
                className="mt-6"
                name="update-student-id"
                label="Student ID"
                placeholder="Enter Student ID"
                error={!!studentIdInputError}
                helperText={studentIdInputHelper}
                required={!!enrollmentMissingStudentId}
                onChange={(event) => setSelectedStudentId(event.currentTarget.value)}
              />
            </When>
          </form>
        )}
      </CenteredFormControl>
      <Stack alignItems="center">
        <Text variant="legal">
          If it isn’t the right Beneficiary, or you want to change the Beneficiary your account is
          associated with, please contact support.
        </Text>

        <ConfirmButton
          form="beneficiary-selection-form"
          variant="contained"
          data-cy="confirm-beneficiary"
          onClick={handleConfirmBeneficiary}
          disabled={enrollmentMissingStudentId ? !(selectedStudentId && selectedStudent) : !selectedStudent}>
          {"Confirm"}
        </ConfirmButton>

        {!!error && (
          <Typography mb="1rem" color="error" data-cy="beneficiary-error">
            {error}
          </Typography>
        )}

        <Typography>
          <Link id="intercom_support" href="https://www.backpack529.com/contact" underline="none">
            Contact support
          </Link>
        </Typography>
      </Stack>
    </Content>
  );
};

export default BeneficiarySelection;
