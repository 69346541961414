import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { When } from "react-if";

import Button from "@components/Button/Button";
import Stepper from "@components/Stepper/Stepper";
import InputCurrency from "@components/Input/InputCurrency";
import Well from "@components/Well";

import { useFormValidation } from "@hooks/useFormValidation";

import { useAddFundsStore } from "@stores/addFundsStore";

import { ADD_FUNDS_STEP_DETAILS } from "@lib/constants/addFunds";
import { FORM_ERROR } from "@lib/constants/error";

import { State529ProviderType } from "@lib/enums/state529";
import { AddFundsSteps } from "@lib/enums/flows";

const FORM_ID = "Add529FundsAmount-form";

const Add529FundsAmount = () => {
  const step = useAddFundsStore(useShallow((state) => state.step));

  const setAmount = useAddFundsStore(useShallow((state) => state.setAmount));
  const goToNextStep = useAddFundsStore((state) => state.goToNextStep);

  const description = useMemo(() => {
    if (!step.details) return ADD_FUNDS_STEP_DETAILS[State529ProviderType.DIRECT_DEBIT][AddFundsSteps.AMOUNT].description;

    return step.details[AddFundsSteps.AMOUNT].description;
  }, [step]);

  const {
    errors,
    isValidForm,
    setErrors,
    setFormErrors,
    validateFormField,
  } = useFormValidation(FORM_ID);

  const formErrorMessage = useMemo(() => {
    if (!!errors && Object.values(errors).some((error) => !!error.length)) {
      return FORM_ERROR.REQUIRED;
    }

    return "";
  }, [errors]);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    const isValid = (event.target as HTMLFormElement).checkValidity();
    const hasErrors = errors && Object.values(errors).some((error) => !!error.length);

    const amount = formData.get("withdrawal_amount") as string;

    if (!isValid || !amount) {
      setFormErrors(formData);
      return;
    }

    // if the form is valid but used to have errors
    // courtesey reset all errors
    if (isValid && hasErrors) setErrors(null);

    setAmount(amount);
    goToNextStep();
  };

  return (
    <>
      <header className="modal-content-fixed flex-col px-12">
        <h1 className="text-blue text-lg font-montserrat font-bold text-center mb-5" data-testid="Add529FundsAmount-heading">Amount</h1>

        <When condition={step.list && !!step.list.length}>
          <Stepper
            steps={step.list}
            current={step.stepNumber}
            className="mb-10"
          />
        </When>

        <p className="text-base font-bold font-montserrat text-indigo" data-testid="Add529FundsAmount-description">
          {description}
        </p>
      </header>

      <main className="modal-screen-footer-fixed px-12 modal-body-scroll">
        <form
          id={FORM_ID}
          data-testid="Add529FundsAmount-form"
          className="w-full mt-6"
          noValidate
          onSubmit={handleFormSubmit}
        >
          <When condition={!!formErrorMessage}>
            <Well color="error" className="font-semibold mb-5">
              {formErrorMessage}
            </Well>
          </When>

          <InputCurrency
            required
            id="withdrawal-amount"
            data-testid="Add529FundsAmount-amount-input"
            name="withdrawal_amount"
            label="Enter Amount"
            hideLabel
            placeholder="0.00"
            minAmount={0}
            error={errors?.withdrawal_amount}
            onBlur={(event) => validateFormField({
              inputName: "withdrawal_amount",
              isValid: !!event.currentTarget.value
            })}
          />
        </form>

        <div className="w-full shrink-0">
          <Button
            rounded
            fullWidth
            type="submit"
            form={FORM_ID}
            data-testid="Add529FundsAmount-continue-btn"
            disabled={!isValidForm}
            onClick={() => handleFormSubmit}
          >
            Continue
          </Button>
        </div>
      </main>
    </>
  );
};

export default Add529FundsAmount;