import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";

import SuccessImage from "assets/images/successStudent.svg";
import DescriptionList from "@components/DescriptionList/DescriptionList";
import Button from "@components/Button/Button";

import { useAddFundsStore } from "@stores/addFundsStore";

import { ADD_FUNDS_STEP_DETAILS } from "@lib/constants/addFunds";

import { State529ProviderType } from "@lib/enums/state529";
import { AddFundsSteps } from "@lib/enums/flows";

import { formatDateStringToShortMonth } from "@utils/formatDates";

type Add529FundsSuccessProps = {
  onFinish: () => void;
}

const Add529FundsSuccess = ({ onFinish }: Add529FundsSuccessProps) => {
  const details = useAddFundsStore(useShallow((state) => state.step.details));
  const amount = useAddFundsStore(useShallow((state) => state.payload.amount));
  const confirmation = useAddFundsStore(useShallow((state) => state.confirmation));

  const subHeading = useMemo(() => {
    if (!details) return ADD_FUNDS_STEP_DETAILS[State529ProviderType.DIRECT_DEBIT][AddFundsSteps.SUCCESS].subHeading;

    return details[AddFundsSteps.SUCCESS].subHeading;
  }, [details]);

  const confirmationDetails = useMemo(() => {
    if (!confirmation) return [];

    return [{
      term: "Withdrawal Confirmation #",
      details: `${confirmation.withdrawal_request_id}`,
    },
    {
      term: "Amount",
      details: `$${amount}`,
    },
    {
      term: "Date",
      details: formatDateStringToShortMonth(new Date().toISOString())
    }];
  }, [confirmation]);

  return (
    <>
      <header className="modal-content-fixed flex-col px-12 items-center">
        <h1 className="text-blue text-4xl font-montserrat font-bold text-center mb-5" data-testid="Add529FundsSuccess-heading">Success!</h1>
      </header>


      <main className="modal-screen-footer-fixed px-12 modal-body-scroll">
        <div className="flex flex-col items-center mt-14 w-full">
          <p className="text-xl font-bold font-montserrat text-indigo text-center max-w-60 mb-4" data-testid="Add529FundsSuccess-subheading">
            {subHeading}
          </p>

          <img
            src={SuccessImage}
            alt="Person celebrating success"
            height="186"
            width="276"
          />

          <DescriptionList
            data-testid="Add529FundsSuccess-confirmation"
            list={confirmationDetails}
            containerClassName="w-full mt-14"
            listItemClassName="py-3.5"
          />
        </div>

        <div className="w-full shrink-0">
          <Button
            rounded
            fullWidth
            data-testid="Add529FundsSuccess-finish-btn"
            onClick={onFinish}
          >
            Finish
          </Button>
        </div>
      </main>
    </>
  );
};

export default Add529FundsSuccess;