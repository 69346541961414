import { create } from "@stores/utils";
import { persist, createJSONStorage } from "zustand/middleware";

import { State529ProviderType } from "@lib/enums/state529";

import { checkProfileStatus } from "@helpers/checkProfileStatus";

import type {
  OnboardingProfile,
  UserProfiles,
  UserProfileTypes
} from "@lib/types/userProfile";

type UpdateUserProfile = {
  type: UserProfileTypes;
  update: Partial<UserProfiles>;
  updateStatus?: boolean;
}

type UserProfileState = {
  profiles: {
    onboarding: OnboardingProfile;
  };
  statusComplete: {
    onboarding: boolean;
  };
  initialized: boolean;
};

type UserProfileAction = {
  setInitialProfiles: (initialUserProfiles: Partial<UserProfileState>) => void;
  updateProfile: ({ type, update }: UpdateUserProfile) => void;
  updateProfileStatus: (update: Partial<UserProfileState["statusComplete"]>) => void;
}

const DEFAULT_INITIAL_STATE = {
  profiles: {
    onboarding: {
      hasBeneficiary: false,
      hasInstitutionPayments: false,
      hasTransactions: false,
      hasLinked529: false,
      hasSkippedTuitionPayment: null,
      hasSkippedManualLinking: null,
      hasSkippedDirectDebitLinking: null,
      planProviderType: State529ProviderType.MANUAL,
      planType: null,
    },
  },
  statusComplete: {
    onboarding: false,
  },
  initialized: false
};

export const useUserProfileStore = create<UserProfileState & UserProfileAction>()(
  persist(
    (set, get) => ({
      ...DEFAULT_INITIAL_STATE,

      setInitialProfiles: (initialProfileState) => {
        const hasInitialized = get().initialized;

        if (!hasInitialized) {
          set({
            ...initialProfileState,
            initialized: true,
          });
        }
      },

      updateProfile: ({ type, update, updateStatus }) => {
        const currentProfile = get().profiles[type];
        const updatedProfile = { ...currentProfile, ...update };

        // only update the profile
        if (!updateStatus) {
          set((state) => ({
            ...state,
            profiles: {
              ...state.profiles,
              [type]: updatedProfile
            },
          }));

          return;
        }

        const isProfileComplete = checkProfileStatus(type, updatedProfile);

        set((state) => ({
          ...state,
          profiles: {
            ...state.profiles,
            [type]: updatedProfile
          },
          statusComplete: {
            ...state.statusComplete,
            [type]: isProfileComplete
          }
        }));
      },

      updateProfileStatus: (update) => {
        set((state) => ({
          ...state,
          statusComplete: {
            ...state.statusComplete,
            ...update
          }
        }));
      }
    }),
    {
      name: "profile",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);