import React from "react";
import { When } from "react-if";

import { classnames } from "@lib/utils/classnames";

export type DescriptionListProps = {
  containerClassName?: string;
  listItemClassName?: string;
  table?: boolean;
  title?: string;
  list: {
    term: string | React.ReactNode;
    details: string | React.ReactNode;
  }[];
};

const DescriptionList = React.memo(function DescriptionList({
  containerClassName,
  listItemClassName,
  list,
  title,
  table = false
}: DescriptionListProps) {
  if (!list || list.length === 0) return null;

  return (
    <div className={classnames("rounded", {
        "overflow-hidden": table,
      }, containerClassName)}>
      <When condition={title}>
        <div className={classnames("py-3 border-b border-gray-200", {
          "px-4": table
        })}>
          <h3 className="text-lg font-bold leading-7 text-primary">{title}</h3>
        </div>
      </When>

      <div className={classnames("text-primary", {
        "text-md": table,
        "text-sm": !table,
      })}>
        <dl>
          {list.map(({ details, term }) => (
            <When condition={!!details} key={`${title}-${term}`}>
              <div className={classnames({
                "py-4 px-6 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-4": table,
                "flex justify-between gap-2 py-4 first:pt-0": !table
              }, listItemClassName)}>
                <dt className={classnames({
                  "font-bold": table,
                  "font-medium": !table
                })}>{term}</dt>

                <dd className={classnames({
                  "mt-1 leading-6 lg:col-span-2 sm:mt-0": table, "text-right font-bold": !table
                })}>{details}</dd>
              </div>
            </When>
          ))}
        </dl>
      </div>
    </div>
  );
});

export default DescriptionList;