import { useState } from "react";
import { When } from "react-if";

import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import ModalDialog from "@components/Modal/ModalDialog";

import Add529Funds from "@components/AddFunds/Add529Funds/Add529Funds";
import { useAddFundsStore } from "@stores/addFundsStore";
import { useNavigate } from "react-router-dom";
import { BASE_ROUTE } from "routes/constants";

type AddFundsModalProps = {
  open: boolean;
  closeModal: () => void;
  onAddFundsCompleted: () => void;
}

const AddFundsModal = ({
  open,
  closeModal,
  onAddFundsCompleted
}: AddFundsModalProps) => {
  const navigate = useNavigate();

  const [openExitConfirmation, setOpenExitConfirmation] = useState(false);
  const resetAddFundsStore = useAddFundsStore((state) => state.reset);

  const handleConfirmExit = () => {
    resetAddFundsStore();
    setOpenExitConfirmation(false);
    closeModal();
    navigate(BASE_ROUTE.dashboard, { replace: true });
  };

  const handleCloseModal = () => {
    resetAddFundsStore();
    onAddFundsCompleted();
  };

  return (
    <>
      <Modal
        open={open}
        maxWidth="xs"
        className="sm:h-[calc(100%-20px)] sm:max-h-[932px]"
        showCloseButton
        scrollScreen
        handleClose={() => setOpenExitConfirmation(true)}
      >
        <Add529Funds onCloseModal={() => handleCloseModal()} />
      </Modal>

      <When condition={openExitConfirmation}>
        <ModalDialog open={openExitConfirmation} onClose={() => setOpenExitConfirmation(false)}>
          <h3 className="text-xl font-montserrat font-bold">Exiting Withdrawal Request</h3>

          <p className="mt-4 text-base">
            Your withdrawal has not been made yet.
            Are you sure you want to leave?
          </p>

          <div className="mt-4">
            <Button
              primary
              rounded
              fullWidth
              data-testid="dialog-continue-withdrawal-button"
              onClick={() => setOpenExitConfirmation(false)}
            >
              Continue Withdrawal
            </Button>

            <Button
              text
              rounded
              size="sm"
              className="mt-1.5 font-medium"
              data-testid="dialog-exit-withdrawal-button"
              onClick={handleConfirmExit}
            >
              Exit Withdrawal
            </Button>
          </div>
        </ModalDialog>
      </When>
    </>
  );
};

export default AddFundsModal;