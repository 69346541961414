import React, { useEffect, useMemo, useState } from "react";
import { When } from "react-if";
import { useMutation } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";

import Button from "@components/Button/Button";
import InputText from "@components/Input/InputText";
import InputCurrency from "@components/Input/InputCurrency";
import Select, { type SelectOption } from "@components/Select/Select";
import DescriptionList, { type DescriptionListProps } from "@components/DescriptionList/DescriptionList";
import Spinner from "@components/Spinner/Spinner";
import Well from "@components/Well";
import Tooltip from "@components/Tooltip/Tooltip";
import InformationCircleIcon from "@components/Icons/InformationCircleIcon";

import { ERROR_TYPE, FORM_ERROR } from "@lib/constants/error";

import { TuitionPaymentSteps } from "@lib/enums/flows";
import { State529ProviderType } from "@lib/enums/state529";

import { useFormValidation } from "@hooks/useFormValidation";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { updateBeneficiaryEnrollment } from "@lib/mutations/beneficiaryMutations";

import { useCurrentUserStore } from "@stores/currentUserStore";
import { useTuitionPaymentsStore } from "@stores/tuitionPaymentStore";

import { formatAmountToCents, formatAmountInCentsToCurrency } from "@utils/formatAmounts";

import type { Enrollment } from "@lib/types/beneficiary";

const AmountToolTip = () => {
  return (
    <Tooltip text="Enter the outstanding balance amount that you want to pay with your 529 plan" position="top">
      <span className="sr-only">More Info</span>
      <InformationCircleIcon
        outline
        aria-hidden={true}
        strokeWidth={3}
        className="size-4 text-gray-500"
      />
    </Tooltip>
  );
};

const TuitionPaymentNewScreen = () => {
  const [isPendingSubmit, setIsPendingSubmit] = useState<boolean>(false);

  const {
    errors,
    isValidForm,
    setErrors,
    setFormErrors,
    validateFormField,
    validateForm,
  } = useFormValidation("tuition-payment-form");

  const form = document.getElementById("tuition-payment-form");
  const beneficiary = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries[0]));

  const currentStep = useTuitionPaymentsStore(useShallow((state) => state.step.current));
  const errorMessage = useTuitionPaymentsStore(useShallow((state) => state.errorMessage));
  const existingAmount = useTuitionPaymentsStore(useShallow((state) => state.tuitionPaymentPayload.amount_in_cents));

  const state529PlanDetails = useTuitionPaymentsStore(useShallow((state) => state.state529PlanDetails));
  const planProviderType = useTuitionPaymentsStore(useShallow((state) => state.state529PlanDetails.planProviderType));

  const setErrorMessage = useTuitionPaymentsStore((state) => state.setErrorMessage);
  const updatePaymentPayload = useTuitionPaymentsStore((state) => state.updateTuitionPaymentPayload);
  const updateEnrollment = useCurrentUserStore((state) => state.updateBeneficiaryEnrollment);

  const goToNextStep = useTuitionPaymentsStore((state) => state.goToNextStep);
  const showSkipTuitionDialog = useTuitionPaymentsStore((state) => state.showSkipTuitionDialog);

  const title = useMemo(() => {
    if (planProviderType === State529ProviderType.DIRECT_DEBIT) return "Pay Tuition";

    return "Confirm attendance & pay tuition";
  }, [planProviderType]);

  const description = useMemo(() => {
    if (planProviderType !== State529ProviderType.DIRECT_DEBIT) {
      return "If you have a Tuition Bill to pay, confirm the details below to let your university know you are paying with your 529.";
    }

    const state529PlanName = state529PlanDetails.state529Plan?.name || "529";

    return `Review your tuition payment details below. The amount will be debited from your ${state529PlanName}.`;
  }, [planProviderType, state529PlanDetails]);

  const studentId = useMemo(() => {
    return beneficiary.enrollments[0].student_id || "";
  }, [beneficiary]);

  const beneficiaryDetails = useMemo(() => {
    const details = [{
      term: "Recipient",
      details: beneficiary.enrolled_institutions[0].name
    },
    {
      term: "Student",
      details: `${beneficiary.first_name} ${beneficiary.last_name}`
    },
    {
      term: "Student ID",
      details: studentId
    }] as DescriptionListProps["list"];

    if (state529PlanDetails.planProviderType === State529ProviderType.DIRECT_DEBIT) {
      const planName = state529PlanDetails.state529Plan?.name || "";
      const accountNumber = state529PlanDetails.account_number;
      const routingNumber = state529PlanDetails.routing_number;

      const planDetails = (<>
        {planName}{" "}
        {accountNumber && routingNumber &&
          <span className="block font-normal text-gray-500">{`Account **${accountNumber} Routing **${routingNumber}`}</span>
        }
      </>);

      details.unshift({
        term: "From",
        details: planDetails
      });
    }

    return details;
  }, [
    beneficiary,
    studentId,
    state529PlanDetails
  ]);

  const active_terms = useMemo(() => {
    return beneficiary.enrolled_institutions[0].periods.map((term) => {
      if (!term.is_active) return;

      return {
        label: term.description,
        value: term.id
      };
    }).filter((term) => term);
  }, [beneficiary]);

  const {
    mutate: updateStudentId,
    error: updateStudentIdError,
  } = useMutation({
    mutationKey: [MUTATION_KEY.UPDATE_BENEFICIARY_ENROLLMENT],
    mutationFn: updateBeneficiaryEnrollment,
    onError: (error) => {
      if (error.name === ERROR_TYPE.INVALID_FORMAT) {
        setErrorMessage(error.message);
        setIsPendingSubmit(false);
      } else {
        throw error;
      }
    },
    onSuccess: (results) => {
      updateEnrollment({
        beneficiary_id: beneficiary.id,
        enrollment: results as Enrollment
      });

      goToNextStep();
    }
  });

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsPendingSubmit(true);

    const formData = new FormData(event.target as HTMLFormElement);
    const isValid = (event.target as HTMLFormElement).checkValidity();
    const hasErrors = errors && Object.values(errors).some((error) => !!error.length);

    // if the form is invalid, show an error message
    // and make sure all invalid inputs are in an error state
    if (!isValid) {
      setFormErrors(formData);
      setIsPendingSubmit(false);
      return;
    }

    // if the form is valid but used to have errors
    // courtesey reset all errors
    if (isValid && hasErrors) setErrors(null);

    const {
      tuition_amount,
      institution_period_id,
      student_id
    } = Object.fromEntries(formData);

    // format and update payload in store
    await updatePaymentPayload({
      amount_in_cents: formatAmountToCents(tuition_amount as string),
      beneficiary_enrollment_id: beneficiary.enrollments[0].id,
      institution_period_id: institution_period_id as string,
    });

    // if there is a student id,
    // update the beneficiary's enrollment
    if (student_id) {
      await updateStudentId({
        beneficiary_id: beneficiary.id,
        enrollment_id: beneficiary.enrollments[0].id,
        payload: { student_id: student_id as string }
      });
    } else {
      goToNextStep();
    }
  };

  // reset the enableSubmit to true
  // when the step changes back to NEW from MFA
  useEffect(() => {
    if (currentStep === TuitionPaymentSteps.NEW) {
      setIsPendingSubmit(false);
    }
  }, [currentStep]);

  // when the form has loaded,
  // check to see if the button can be enabled
  useEffect(() => {
    if (!form) return;

    validateForm();
  }, [form]);

  const formErrorMessage = useMemo(() => {
    if (updateStudentIdError && updateStudentIdError.name === ERROR_TYPE.INVALID_FORMAT) {
      return FORM_ERROR.INVALID_FORMAT;
    }

    if (!!errors && Object.values(errors).some((error) => !!error.length)) {
      return FORM_ERROR.REQUIRED;
    }

    return "";
  }, [errors, updateStudentIdError]);

  const studentIdError = useMemo(() => {
    if (updateStudentIdError) {
      return updateStudentIdError.message;
    }

    return errors?.student_id;
  }, [
    errors?.student_id,
    updateStudentIdError,
    errorMessage
  ]);

  return (
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title" data-testid="tuition-payment-screen-header">{title}</h2>

        <p className="modal-header-description" data-testid="tuition-payment-screen-description">{description}</p>

        <form
          noValidate
          id="tuition-payment-form"
          data-testid="tuition-payment-form"
          className="mt-6 flex flex-col gap-4 w-full"
          onSubmit={handleFormSubmit}
        >
          <When condition={!!formErrorMessage}>
            <Well color="error" className="font-semibold">
              {formErrorMessage}
            </Well>
          </When>

          <DescriptionList list={beneficiaryDetails} />

          <When condition={!studentId}>
            <InputText
              required
              id="student-id"
              data-testid="student-id"
              name="student_id"
              label="Student ID number"
              placeholder="Enter student id number"
              error={studentIdError}
              onBlur={(event) => validateFormField({
                inputName: "student_id",
                isValid: !!event.currentTarget.value
              })}
            />
          </When>

          <InputCurrency
            required
            id="tuition-payment-amount"
            data-testid="tuition-payment-amount"
            name="tuition_amount"
            label="Enter Amount"
            placeholder="0.00"
            minAmount={0}
            maxAmount={100000}
            className="pl-6"
            defaultValue={existingAmount ? formatAmountInCentsToCurrency(existingAmount) : ""}
            tooltip={<AmountToolTip />}
            error={errors?.tuition_amount}
            onBlur={(event) => validateFormField({
              inputName: "tuition_amount",
              isValid: !!event.currentTarget.value
            })}
          />

          <Select
            data-testid="tuition-payment-enrollment-term"
            required
            id="enrollment-term"
            name="institution_period_id"
            label="Confirm Term"
            className="py-2.5"
            options={active_terms as SelectOption[]}
            onChange={(event) => validateFormField({
              inputName: "institution_period_id",
              isValid: !!event.currentTarget.value
            })}
          />
        </form>
      </div>

      <div className="w-full shrink-0 flex justify-between gap-4">
        <Button
          secondary
          rounded
          fullWidth
          data-testid="tuition-payment-skip-button"
          disabled={isPendingSubmit}
          onClick={showSkipTuitionDialog}
        >
          Skip
        </Button>

        <Button
          rounded
          fullWidth
          type="submit"
          form="tuition-payment-form"
          data-testid="tuition-payment-submit-button"
          data-gtm="tuition-payment-submit"
          disabled={!isValidForm || isPendingSubmit}
          onClick={() => handleFormSubmit}
        >
          {isPendingSubmit
            ? <Spinner color="white" />
            : "Continue"
          }
        </Button>
      </div>
    </div>
  );
};

export default TuitionPaymentNewScreen;