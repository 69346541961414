/**
 * Enum for a state 529's connection status
 *
 * used as part of State529Connection["status"]
 *
 * LINKED, NEEDS_RELINK, NOT_LINKED
 */
export enum State529ConnectionStatus {
  LINKED = "LINKED",
  NEEDS_RELINK = "NEEDS_RELINK",
  NOT_LINKED = "NOT_LINKED"
}

/**
 * Enum for a state 529's plan provider
 *
 * ACENSUS = Acensus
 * FIDELITY = Fidelity
 */
export enum State529Provider {
  ASCENSUS = "Ascensus",
  FIDELITY = "Fidelity"
}

/**
 * Enum for a state 529's plan provider type
 *
 * AUTOMATED, MANUAL, DIRECT_DEBIT
 */
export enum State529ProviderType {
  AUTOMATED = "automated",
  MANUAL = "manual",
  DIRECT_DEBIT = "direct_debit"
}