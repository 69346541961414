/**
 * Enum for modal names
 *
 * LINK_529, PAY_TUITION, WITHDRAWAL
 */
export enum ModalType {
  LINK_529 = "LINK_529",
  PAY_TUITION = "PAY_TUITION",
  WITHDRAWAL = "WITHDRAWAL",
  PENDING_TUITION_PAYMENT = "PENDING_TUITION_PAYMENT",
  ADD_FUNDS = "ADD_FUNDS"
}