import { useCallback, useMemo, useState } from "react";
import { VALIDITY_STATE_ERROR } from "@lib/constants/error";

type FormErrors = {
  [key: string]: string;
}

type ValidateFormFieldProps = {
  inputName: string;
  isValid: boolean;
}

export const useFormValidation = (formId: string) => {
  const [errors, setErrors] = useState<FormErrors | null>(null);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  const validateForm = () => {
    const form = document.getElementById(formId) as HTMLFormElement;

    if (!form) {
      setIsValidForm(false);
      return;
    }

    const isValid = form.checkValidity();

    setIsValidForm(isValid);
  };

  const validateFormField = useCallback(({ inputName, isValid }: ValidateFormFieldProps) => {
    const input = document.querySelector(`input[name='${inputName}']`) as HTMLInputElement;

    if (!input) return;

    const validityState = input.validity;

    let errorMessage = isValid || validityState.valid
      ? ""
      : VALIDITY_STATE_ERROR.valueMissing;

    if (!validityState.valid && validityState.customError) {
      errorMessage = input.validationMessage;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: errorMessage
    }));

    setTimeout(validateForm, 0);
  }, []);

  const setFormErrors = useCallback((formData: FormData) => {
    const formErrors: FormErrors = { ...errors };

    for (const inputName of formData.keys()) {
      const input = document.getElementsByName(inputName)[0] as HTMLInputElement;
      const isInputValid = input.validity.valid;
      let errorMessage = isInputValid ? "" : VALIDITY_STATE_ERROR.valueMissing;

      if (!isInputValid && input.validity.customError) {
        errorMessage = input.validationMessage;
      }

      formErrors[inputName] = errorMessage;
    }

    setErrors(formErrors);
  }, []);

  const hasErrors = useMemo(() => {
    return !!errors && Object.values(errors).some((error) => !!error.length);
  }, [errors]);

  return {
    errors,
    isValidForm,
    hasErrors,
    setErrors,
    setFormErrors,
    validateFormField,
    validateForm
  };
};