import React, { FC } from "react";
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { formatCurrency, sanitizeAmount } from "utils/formats";
import type { PaymentInfo } from "@lib/types/institutionPayment";
import type { InstitutionTerm } from "@lib/types/institution";

export type EducationInstitution = {
  key: string;
  name: string;
};

const Content = styled(Stack)(({ theme }) => ({
  margin: "2.75rem 1.75rem 1.75rem 1.75rem",
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    margin: "2.75rem 0",
    width: "100%",
  },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

interface PaymentDetailsProp {
  handleNext: () => void;
  updatePaymentInfo: (info: Partial<PaymentInfo>) => void;
  paymentInfo: Partial<PaymentInfo>;
}

const PaymentDetails: FC<PaymentDetailsProp> = ({ handleNext, updatePaymentInfo, paymentInfo }) => {
  const [amount, setAmount] = React.useState("");
  const [term, setTerm] = React.useState<string | null>(null);
  const [error, setError] = React.useState("");
  const [loading] = React.useState(false);

  const terms = React.useMemo(() => {
    return (
      paymentInfo.recipient?.periods
        .filter((term: InstitutionTerm) => term.is_active)
        .map((term: InstitutionTerm) => term.description) || []
    );
  }, [paymentInfo.recipient]);

  const isInvalidInformation = () => !amount || sanitizeAmount(amount) <= 0 || !term;

  const handleConfirmInstitutions = () => {
    if (isInvalidInformation()) {
      setError("Please fill in all fields");
      return;
    }

    const selectedPeriod = paymentInfo.recipient?.periods.find(
      (period: InstitutionTerm) => period.description === term,
    );

    updatePaymentInfo({ amount, term: selectedPeriod });
    handleNext();
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value.replace(/[^0-9]/g, ""));
    const formattedValue = formatCurrency(value);
    setAmount(formattedValue);
  };

  return (
    <Content>
      <Stack alignItems="center" spacing={4}>
        <FormControl>
          <TextField
            name="amount"
            label="Amount"
            data-cy="payment-amount-input"
            value={amount}
            onChange={handleAmountChange}
            placeholder="$0.00"
            helperText="Enter the amount you want the institution to receive"
          />
        </FormControl>
        <FormControl>
          <Autocomplete
            data-cy="payment-institution-term"
            disablePortal
            value={term}
            // @ts-ignore
            onChange={(e, newValue) => setTerm(newValue)}
            options={terms}
            renderInput={(params) => (
              <TextField
                name="terms"
                {...params}
                label="Term"
                helperText="Select the term you want to apply the payment to"
              />
            )}
          />
        </FormControl>

        <ConfirmButton
          data-cy="payment-details-confirm-button"
          variant="contained"
          onClick={handleConfirmInstitutions}
          disabled={isInvalidInformation()}>
          {loading ? <CircularProgress size="1.7rem" sx={{ color: "white" }} /> : "Confirm"}
        </ConfirmButton>

        {!!error && (
          <Typography mb="1rem" color="error" data-cy="payment-details-error">
            {error}
          </Typography>
        )}
      </Stack>
    </Content>
  );
};

export default PaymentDetails;
