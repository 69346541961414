import * as Sentry from "@sentry/react";
import config from "config/index";

import packageJson from "../../package.json";
import { ApiError } from "@lib/utils/errors";
import { useAuthStore } from "@stores/authStore";

import { ERROR_TYPE } from "@lib/constants/error";

const { api } = config;

const VERSION = api.version;
const API_HOST = api.url;
const API_BASE_URL = api.baseUrl;
const URL = `${API_HOST}/${API_BASE_URL}/${VERSION}`;

const getToken = (): string | null => {
  return localStorage.getItem("token");
};

const { removeToken } = useAuthStore.getState();

async function get(endpoint: string) {
  const token = getToken();

  return fetch(`${URL}${endpoint}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "x-backpack-client-version": packageJson.version,
    },
    redirect: "follow",
  }).then(async (res) => {
    const data = await res.json();

    if (!res.ok) {
      const error = new ApiError(data);

      if (error.name === ERROR_TYPE.ACCESS_DENIED_EXCEPTION || error.name === ERROR_TYPE.SESSION_EXPIRED) {

        Sentry.withScope(function (scope) {
          scope.setLevel("log");
          Sentry.captureException(error);
        });

        removeToken();
        throw error;
      }

      console.error(error);
      Sentry.captureException(error);

      throw { response: { data } };
    }

    return { data };
  });
}

async function patch(endpoint: string, body?: object) {
  const token = getToken();

  return fetch(`${URL}${endpoint}`, {
    method: "PATCH",
    body: body ? JSON.stringify(body) : null,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "x-backpack-client-version": packageJson.version,
    },
    redirect: "follow",
  }).then(async (res) => {
    const data = await res.json();

    if (!res.ok) {
      const error = new ApiError(data);

      if (error.name === ERROR_TYPE.ACCESS_DENIED_EXCEPTION || error.name === ERROR_TYPE.SESSION_EXPIRED) {
        Sentry.withScope(function (scope) {
          scope.setLevel("log");
        });
      }

      console.error(error);
      Sentry.captureException(error);

      throw { response: { data } };
    }

    return { data };
  });
}

async function post(endpoint: string, body?: object) {
  const token = getToken();

  return fetch(`${URL}${endpoint}`, {
    method: "POST",
    body: body ? JSON.stringify(body) : null,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "x-backpack-client-version": packageJson.version,
    },
    redirect: "follow",
  }).then(async (res) => {
    const data = await res.json();

    if (!res.ok) {
      const error = new ApiError(data);

      if (error.name === ERROR_TYPE.ACCESS_DENIED_EXCEPTION || error.name === ERROR_TYPE.SESSION_EXPIRED) {
        Sentry.withScope(function (scope) {
          scope.setLevel("log");
        });
      }

      console.error(error);
      Sentry.captureException(error);

      throw { response: { data } };
    }

    return { data };
  });
}

export default { get, patch, post };