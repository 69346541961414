import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { useMutation } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";
import { When } from "react-if";

import InputText from "@components/Input/InputText";

import { AGREEMENT_TYPE } from "@lib/constants/global";

import { useFormValidation } from "@hooks/useFormValidation";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { add529CounterpartyLink } from "@lib/mutations/state529Mutations";

import { useCurrentUserStore } from "@stores/currentUserStore";

import { isValidNumber } from "@utils/validators";

import type { FormProps } from "@lib/types/ui";
import type { Add529CounterpartyLinkPayload } from "@lib/types/state529";
import { classnames } from "@utils/classnames";
import { getLastFourDigits } from "@utils/stringUtils";

type DirectDebitAccountFormProps = {
  type?: "fidelity" | null;
  onFormSuccess: (accountDetails: { account_number: string; routing_number: string; }) => void;
} & FormProps;

const AGREEMENT = {
  [AGREEMENT_TYPE.ACH]: {
    id: "ach-authorization",
    name: "ach_authorization",
    label: "I authorize Backpack to initiate debit entries for the specified amount and any future entries under this authorization. This authorization remains in effect until revoked in writing by emailing support@backpack529.com, and I agree to the terms provided, including how to cancel this authorization.",
  },
  [AGREEMENT_TYPE.TERMS_OF_SERVICE]: {
    id: "terms-of-service-agreement",
    name: "terms_of_service_agreement",
    label: <>I confirm that I have read, understood, and agree to the <a href="https://www.backpack529.com/legal/terms-of-service" target="_blank" rel="noreferrer" className="link">Terms of Service</a> and <a href="https://www.backpack529.com/legal/e-sign-consent-agreement" target="_blank" rel="noreferrer" className="link">E-Sign Consent Agreement</a>.</>,
    text: "I confirm that I have read, understood, and agree to the Terms of Service and E-Sign Consent Agreement."
  }
};

const AGREEMENTS = [AGREEMENT_TYPE.ACH, AGREEMENT_TYPE.TERMS_OF_SERVICE];

const isInputValid = (value: string) => {
  if (!value || !isValidNumber(value)) return false;

  return Number(value) > 0;
};

const DirectDebitAccountForm = forwardRef<Partial<HTMLFormElement> | null, DirectDebitAccountFormProps>(({
  formId,
  type = null,
  onPendingSubmit,
  onIsValidForm,
  onFormSuccess
}: DirectDebitAccountFormProps, ref) => {
  const formRef = useRef<HTMLFormElement | null>(null);

  useImperativeHandle(ref, () => ({
    ...formRef.current,
    submitForm: () => {
      if (formRef.current) {
        formRef.current.requestSubmit();
      }
    },
  }), []);

  const {
    isValidForm,
    errors,
    hasErrors,
    setErrors,
    setFormErrors,
    validateFormField,
  } = useFormValidation(formId);

  const [isPendingSubmit, setIsPendingSubmit] = useState<boolean>(false);

  const currentUser = useCurrentUserStore(useShallow((state) => state.currentUser));
  const beneficiaries = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries));

  const accountHolderName = useMemo(() => {
    return `${currentUser.first_name} ${currentUser.last_name}`;
  }, [currentUser]);

  const accountNumberLabel = `${type === "fidelity" ? "Fidelity " : ""}Account Number`;
  const routingNumberLabel = `${type === "fidelity" ? "Fidelity " : ""}Routing Number`;

  const handleValidation = (event: React.FocusEvent<HTMLInputElement>) => {
    const input = event.currentTarget;
    const inputName = input.name;
    const inputLength = input.value.toString().length;

    const isValid = isInputValid(input.value);

    let errorMessage = `Please enter a valid ${inputName === "account_number" ? "account" : "routing"} number.`;

    if (inputName === "routing_number") {
      errorMessage = `${errorMessage} Routing numbers should be 9 digits long.`;
    }

    if (!isValid || (inputLength <= (input.minLength - 1))) {
      input.setCustomValidity(errorMessage);
    } else {
      input.setCustomValidity("");
    }

    validateFormField({ inputName, isValid });
  };

  // add error handling when
  // we need to reuse this form for the personal debit flow
  // and/or when users need to choose a beneficiary
  const {
    mutate,
    isPending,
    isSuccess
  } = useMutation({
    mutationKey: [MUTATION_KEY.ADD_529_COUNTERPARTY_LINK],
    mutationFn: add529CounterpartyLink,
    throwOnError: true,
    // @ts-expect-error
    onSuccess: (results, variables) => {
      onFormSuccess({
        account_number: getLastFourDigits(variables.payload.account_number),
        routing_number: getLastFourDigits(variables.payload.routing_number),
      });
    },
  });

  const handleFormSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();

    setIsPendingSubmit(true);

    const form = document.querySelector(`#${formId}`) as HTMLFormElement;
    const formData = new FormData(form);

    const isValid = (event.target as HTMLFormElement).checkValidity();

    // if the form is invalid, show an error message
    // and make sure all invalid inputs are in an error state
    if (!isValid) {
      setFormErrors(formData);
      setIsPendingSubmit(false);
      return;
    }

    // if the form is valid but used to have errors
    // courtesey reset all errors
    if (isValid && hasErrors) setErrors(null);

    const payload: Add529CounterpartyLinkPayload["payload"] = {
      account_holder_name: accountHolderName,
      account_number: formData.get("account_number") as string,
      routing_number: formData.get("routing_number") as string,
      beneficiary_id: beneficiaries[0].id,
      ach_authorizations: [
        AGREEMENT[AGREEMENT_TYPE.ACH].label,
        AGREEMENT[AGREEMENT_TYPE.TERMS_OF_SERVICE].text
      ],
      timestamp: new Date().toISOString(),
    };

    mutate({
      state_529_plan_id: beneficiaries[0].state_529_plan_id,
      payload
    });
  }, [formId]);

  const formErrorMessage = useMemo(() => {
    if (hasErrors) {
      return "Please fix the highlighted errors to proceed.";
    }
  }, [errors]);

  const disableVisually = useMemo(() => {
    return isPending || isSuccess;
  }, [isPending, isSuccess]);

  useEffect(() => {
    onIsValidForm(isValidForm);
  }, [isValidForm]);

  useEffect(() => {
    onPendingSubmit && onPendingSubmit(isPendingSubmit);
  }, [isPendingSubmit]);

  return (
    <form
      noValidate
      ref={formRef}
      id={formId}
      className="mt-6 flex flex-col gap-4 w-full"
      data-testid="direct-debit-account-form"
      onSubmit={handleFormSubmit}
    >
      <When condition={formErrorMessage}>
        <span className="text-error font-semibold" data-testid="form-error-message">
          {formErrorMessage}
        </span>
      </When>

      <InputText
        required
        id="account-number"
        data-testid="account-number"
        name="account_number"
        label={accountNumberLabel}
        minLength={4}
        inputMode="numeric"
        placeholder="Enter Account Number"
        readOnly={disableVisually}
        error={errors?.account_number}
        onBlur={(event) => handleValidation(event)}
      />

      <InputText
        required
        id="routing-number"
        data-testid="routing-number"
        name="routing_number"
        label={routingNumberLabel}
        minLength={9}
        maxLength={9}
        inputMode="numeric"
        placeholder="Enter Routing Number"
        readOnly={disableVisually}
        error={errors?.routing_number}
        onBlur={(event) => handleValidation(event)}
      />

      <p className="font-medium text-sm">
        Don&apos;t know where to find your Fidelity account and routing numbers? <a href="https://www.fidelity.com/customer-service/529-direct-debit" target="_blank" rel="noreferrer" className="link">Find out here</a>
      </p>

      <fieldset>
        <legend className="sr-only">Ach authorization and terms of service agreements</legend>

        <div className="space-y-2">
          {AGREEMENTS.map((item) => (
            <div className="relative flex items-start" key={item}>
              <div className="flex h-6 items-center">
                <input
                  required
                  type="checkbox"
                  className={classnames("input-checkbox", errors?.[AGREEMENT[item].name] && "error")}
                  data-testid={`${AGREEMENT[item].id}-checkbox`}
                  id={AGREEMENT[item].id}
                  name={AGREEMENT[item].name}
                  readOnly={disableVisually}
                  onChange={(event) => validateFormField({
                    inputName: event.currentTarget.name,
                    isValid: event.currentTarget.checked
                  })}
                />
              </div>

              <div className="ml-3 text-sm">
                <label
                  data-testid={`${AGREEMENT[item].id}-label`}
                  htmlFor={AGREEMENT[item].id}
                  className="font-normal text-primary"
                >
                  {AGREEMENT[item].label}
                </label>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    </form>
  );
});

DirectDebitAccountForm.displayName = "DirectDebitAccountForm";

export default DirectDebitAccountForm;