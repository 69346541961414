export const ERROR_TYPE = {
  DUPLICATE_ROW_EXCEPTION: "DUPLICATE_ROW_EXCEPTION",
  DUPLICATE_APPLICATION: "DUPLICATE_APPLICATION",
  DUPLICATE_RECORD: "DUPLICATE_RECORD",
  MFA_CODE_EXCEPTION: "MFA_CODE_EXCEPTION",
  VERIFY_MFA_ERROR: "VERIFY_MFA_ERROR",
  UNEXPECTED_ERROR_LOGIN: "UNEXPECTED_ERROR_LOGIN",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  UNKNOWN_EXCEPTION: "UNKNOWN_EXCEPTION",
  ACCESS_DENIED_EXCEPTION: "ACCESS_DENIED_EXCEPTION",
  INVALID_FORMAT: "INVALID_FORMAT",
  SESSION_EXPIRED: "SESSION_EXPIRED"
} as const;

export const FORM_ERROR_TYPE = {
  INVALID_FORMAT: "INVALID_FORMAT",
  REQUIRED: "REQUIRED"
} as const;

export const VALIDITY_STATE = {
  VALUE_MISSING: "valueMissing",
} as const;

export const FORM_ERROR = {
  [FORM_ERROR_TYPE.INVALID_FORMAT]: "There was a problem submitting the form. Fix the issues below and try again.",
  [FORM_ERROR_TYPE.REQUIRED]: "Please fill out the required fields."
};

export const VALIDITY_STATE_ERROR = {
  [VALIDITY_STATE.VALUE_MISSING]: "This is a required field."
};
