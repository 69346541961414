/**
 * Use with useQuery's queryKey key
 *
 * example queryKey: [QUERY_KEY.ACCOUNT_OWNER]
 */
export const QUERY_KEY = {
  ACCOUNT_OWNER: "account-owner",
  BANK_ACCOUNT: "bank-account",
  BANK_ACCOUNT_BALANCE: "bank-account-balance",
  BENEFICIARIES: "beneficiaries",
  INSTITUTIONS: "institutions",
  INSTITUTION_PAYMENTS: "institution-payments",
  STATE_529_CONNECTION: "state-529-connection",
  STATE_529_COUNTERPARTY: "state-529-counterparty",
  STATE_529_PLANS: "state-529-plans",
  TRANSACTIONS: "transactions"
};

export const DEFAULT_QUERY_OPTIONS = {
  refetchOnMount: false,
  retry: false,
};

export const FOREVER_STALE_QUERY_OPTIONS = {
  staleTime: Infinity,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  retry: false,
};

export const STALE_TIME_5_MINS = 5 * 60 * 1000; // 5 minutes
export const DEFAULT_GC_TIME = 5 * 60 * 1000; // 5 minutes
