import { create } from "@stores/utils";
import { persist, createJSONStorage } from "zustand/middleware";

import { Flow, TuitionPaymentSteps } from "@lib/enums/flows";
import { State529ProviderType } from "@lib/enums/state529";
import { WithdrawalStatus } from "@lib/enums/withdrawal";

import { useOnboardingStore } from "@stores/onboardingStore";

import type { CreateInstitutionPaymentPayload } from "@lib/types/institutionPayment";
import type { State529PlanDetails } from "@lib/types/state529";
import type { UUID } from "@lib/types/global";

type TuitionPaymentQueryParam = {
  [Flow.TUITION_PAYMENT]: TuitionPaymentSteps;
};

export type TuitionPaymentConfirmation = {
  status: WithdrawalStatus | null;
  withdrawal_request_id: UUID;
  amount_in_cents: number;
}

type TuitionPaymentState = {
  step: {
    current: TuitionPaymentSteps | null;
    prev: TuitionPaymentSteps | null;
  };
  screen: {
    current: TuitionPaymentSteps | null;
    prev: TuitionPaymentSteps | null;
  };
  queryParam: TuitionPaymentQueryParam;
  state529PlanDetails: State529PlanDetails;
  tuitionPaymentPayload: CreateInstitutionPaymentPayload;
  tuitionPaymentConfirmation: TuitionPaymentConfirmation | null;
  errorMessage: string | null;
  mfaError: Error | null;
  isOnboarding: boolean;
};

type TuitionPaymentAction = {
  setIsOnboarding: (details: Partial<State529PlanDetails>) => void;
  setState529PlanDetails: (details: Partial<State529PlanDetails>) => void;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  reEnterTuitionPayment: (details: State529PlanDetails, payload: CreateInstitutionPaymentPayload | null) => void;
  setErrorMessage: (errorMessage: string) => void;
  setMFAError: (error: Error) => void;
  clearErrors: () => void;
  updateTuitionPaymentPayload: (update: Partial<CreateInstitutionPaymentPayload>) => void;
  setTuitionPaymentConfirmation: (confirmation: TuitionPaymentConfirmation) => void;
  showSkipTuitionDialog: () => void;
  reset: () => void;
};

const DEFAULT_INITIAL_STATE = {
  step: {
    current: TuitionPaymentSteps.NEW,
    prev: null,
  },
  screen: {
    current: TuitionPaymentSteps.NEW,
    prev: null,
  },
  queryParam: { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.NEW },
  state529PlanDetails: {
    state529Plan: null,
    planProviderType: null,
    account_number: null,
    routing_number: null,
  },
  tuitionPaymentPayload: {
    institution_payment_token: "",
    beneficiary_enrollment_id: "",
    institution_period_id: "",
    amount_in_cents: 0
  },
  tuitionPaymentConfirmation: null,
  errorMessage: null,
  mfaError: null,
  isOnboarding: false,
};

export const useTuitionPaymentsStore = create<TuitionPaymentState & TuitionPaymentAction>()(
  persist(
    (set, get) => ({
      ...DEFAULT_INITIAL_STATE,

      setIsOnboarding: (planDetails) => {
        const state529PlanDetails = get().state529PlanDetails;

        const update = {
          ...state529PlanDetails,
          ...planDetails
        };

        set((state) => ({
          ...state,
          isOnboarding: true,
          state529PlanDetails: update
        }));
      },

      setState529PlanDetails: (planDetails) => {
        set((state) => ({
          ...state,
          state529PlanDetails: {
            ...state.state529PlanDetails,
            ...planDetails
          }
        }));
      },

      goToNextStep: () => {
        const onboardingStore = useOnboardingStore.getState();
        const isOnboarding = get().isOnboarding;

        const currentStep = get().step.current;
        let nextStep;
        let prevStep = null;

        let nextScreen: (TuitionPaymentSteps | null) = TuitionPaymentSteps.NEW;
        let prevScreen = null;

        let queryParam = { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.NEW };

        const planProviderType = get().state529PlanDetails.planProviderType;

        switch (currentStep) {
          case TuitionPaymentSteps.REVIEW:
            if (planProviderType === State529ProviderType.DIRECT_DEBIT) {
              nextStep = TuitionPaymentSteps.INITIATED;
              nextScreen = TuitionPaymentSteps.INITIATED;

              queryParam = { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.INITIATED };
            } else {
              nextStep = TuitionPaymentSteps.CREATED;
              nextScreen = null;

              queryParam = { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.CREATED };
            }
            break;

          case TuitionPaymentSteps.MFA:
            nextStep = TuitionPaymentSteps.REVIEW;
            prevStep = TuitionPaymentSteps.NEW;

            nextScreen = TuitionPaymentSteps.REVIEW;
            prevScreen = TuitionPaymentSteps.NEW;

            queryParam = { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.REVIEW };
            break;

          case TuitionPaymentSteps.NEW:
          default:
            nextStep = TuitionPaymentSteps.MFA;
            prevStep = TuitionPaymentSteps.NEW;

            nextScreen = TuitionPaymentSteps.NEW;
            prevScreen = TuitionPaymentSteps.NEW;
            break;
        }

        if (isOnboarding
          && planProviderType === State529ProviderType.DIRECT_DEBIT
          && nextStep === TuitionPaymentSteps.REVIEW
        ) {
          onboardingStore.updateStepper(TuitionPaymentSteps.REVIEW);
        }

        set({
          step: {
            current: nextStep,
            prev: prevStep
          },
          screen: {
            current: nextScreen,
            prev: prevScreen
          },
          queryParam
        });
      },

      goToPrevStep: () => {
        const onboardingStore = useOnboardingStore.getState();
        const isOnboarding = get().isOnboarding;
        const planProviderType = get().state529PlanDetails.planProviderType;

        if (isOnboarding && planProviderType === State529ProviderType.DIRECT_DEBIT) {
          onboardingStore.updateStepper(TuitionPaymentSteps.NEW);
        }

        set({
          step: {
            current: TuitionPaymentSteps.NEW,
            prev: null
          },
          screen: {
            current: TuitionPaymentSteps.NEW,
            prev: null
          },
          queryParam: { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.NEW }
        });
      },

      reEnterTuitionPayment: (state529PlanDetails, payload) => {
        const existingPayload = get().tuitionPaymentPayload;

        let step = TuitionPaymentSteps.REVIEW;
        let prevStep: (TuitionPaymentSteps | null) = TuitionPaymentSteps.NEW;
        let updatedPayload = { ...existingPayload };
        let queryParam = { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.REVIEW };

        // if there is no payload from
        // and no existing payload from the tuition payment flow
        // then have the user go back to the 1st screen
        if (!payload
          && (!existingPayload.institution_payment_token || !existingPayload.amount_in_cents)
        ) {
          step = TuitionPaymentSteps.NEW;
          prevStep = null;
          queryParam = { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.NEW };
        }

        if (payload
          && (!existingPayload.institution_payment_token || !existingPayload.amount_in_cents)) {
          updatedPayload = payload;
        }

        set((state) => ({
          ...state,
          step: {
            current: step,
            prev: prevStep,
          },
          screen: {
            current: step,
            prev: prevStep,
          },
          tuitionPaymentPayload: updatedPayload,
          state529PlanDetails: {
            ...state.state529PlanDetails,
            ...state529PlanDetails
          },
          isOnboarding: true,
          queryParam,
        }));
      },

      updateTuitionPaymentPayload: (update) => {
        set((state) => ({
          ...state,
          tuitionPaymentPayload: {
            ...state.tuitionPaymentPayload,
            ...update
          }
        }));
      },

      showSkipTuitionDialog: () => {
        set({
          step: {
            current: TuitionPaymentSteps.SKIPPED,
            prev: TuitionPaymentSteps.NEW
          },
          screen: {
            current: TuitionPaymentSteps.NEW,
            prev: TuitionPaymentSteps.NEW
          },
          queryParam: { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.NEW }
        });
      },

      setErrorMessage: (errorMessage) => {
        set({ errorMessage });
      },

      setMFAError: (error) => {
        set({ mfaError: error });
      },

      clearErrors: () => {
        set({
          errorMessage: null,
          mfaError: null
        });
      },

      setTuitionPaymentConfirmation: (confirmation) => {
        set({ tuitionPaymentConfirmation: confirmation });
      },

      reset: () => {
        set(DEFAULT_INITIAL_STATE);
        useTuitionPaymentsStore.persist.clearStorage();
      }
    }),
    {
      name: "tuition-payment",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);