import { useMemo } from "react";
import { If, Then, Else } from "react-if";

import Well from "components/Well";
import WarningIcon from "components/Icons/WarningIcon";
import HourglassIcon from "components/Icons/HourglassIcon";

import { classnames } from "@utils/classnames";

import { State529Provider } from "@lib/enums/state529";
import type { State529Connection } from "@lib/types/state529";

type Link529StatusNoticeProps = {
  className?: string;
  state529Connection: State529Connection
}

const Link529StatusNotice = ({ className, state529Connection }: Link529StatusNoticeProps) => {
    const {
    status,
    is_withdrawal_eligible,
    provider_name
  } = state529Connection;

  const wellClassName = useMemo(() => {
    return classnames("text-sm", className);
  }, [className]);

  if ((status === "LINKED" && is_withdrawal_eligible) || status === "NEEDS_RELINK") {
    return null;
  }

  return (
    <If condition={status === "NOT_LINKED"}>
      <Then>
        <Well className={wellClassName}>
          <div className="flex gap-3 items-center">
            <div className="shrink-0">
              <WarningIcon className="text-warning-icon size-9 mt-1" aria-hidden="true" />
            </div>

            <If condition={provider_name === State529Provider.FIDELITY}>
              <Then>
                <p>Please link your 529 to Backpack and withdraw funds. To get started, <strong>click the “Add 529 Funds” button</strong>.</p>
              </Then>

              <Else>
                <p>Please link your 529 to Backpack and withdraw funds. To get started, <strong>click the “Link 529” button</strong>.</p>
              </Else>
            </If>
          </div>
        </Well>
      </Then>

      {/* else if status === "LINKED" && !is_withdrawal_eligible */}
      <Else>
        <Well className={wellClassName}>
          <div className="flex gap-3 items-center">
            <div className="shrink-0">
              <HourglassIcon
                className="text-gray-700 size-9 mt-1"
                type="top"
                aria-hidden="true"
              />
            </div>

            <p>Your 529 is validating your Backpack bank account. This may take up to 15 days. We&apos;ll notify you when you can add funds. Meanwhile, set up tuition payment.</p>
          </div>
        </Well>
      </Else>
    </If>
  );
};

export default Link529StatusNotice;
