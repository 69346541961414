import * as Sentry from "@sentry/react";

import instance from "config/fetch.config";
import type { UUID } from "@lib/types/global";
import type { Institution } from "@lib/types/institution";
import type { State529Plan } from "@lib/types/state529";
import { errorMapper } from "utils/errors";

class ApplicationServices {
  static async get529Plans(): Promise<State529Plan[]> {
    try {
      const {
        data: { data },
      } = await instance.get("/state-529-plans");
      return data.plans;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async getInstitutions(): Promise<Institution[]> {
    try {
      const {
        data: { data },
      } = await instance.get("/institutions");
      return data.institutions;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async createApplication(phone_number: string): Promise<any> {
    try {
      const {
        data: { data },
      } = await instance.post("/applications", { phone_number });
      return data.application_id;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async getApplicationMFA(applicationId: UUID): Promise<any> {
    try {
      await instance.post(`/applications/${applicationId}/mfa-codes`);
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async getApplicationToken(applicationId: UUID, mfa_code: string): Promise<any> {
    try {
      const {
        data: { data },
      } = await instance.post(`/applications/${applicationId}/tokens`, { mfa_code });

      localStorage.setItem("token", data.token);
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async saveEmail(applicationId: UUID, email: string) {
    try {
      await instance.patch(`/applications/${applicationId}`, { email });
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async verifyEmail(
    applicationId: UUID,
    verificationCode: string,
  ): Promise<{ is_email_verified: boolean }> {
    try {
      const {
        data: { data },
      } = await instance.post(`/applications/${applicationId}/email/verify`, {
        email_verification_code: verificationCode,
      });

      return data;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }
}

export default ApplicationServices;
