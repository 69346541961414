import React from "react";

import { styled } from "@mui/material";
import { Button, Stack, Typography } from "@mui/material";

import Modal from "commons/Modal";
import Dialog from "commons/Modal/Dialog";
import Payment from ".";

import { State529Provider } from "@lib/enums/state529";
import type { CurrentUser } from "@lib/types/currentUser";
import type { State529Connection, State529Plan } from "@lib/types/state529";

type PaymentModalProps = {
  currentUser: CurrentUser;
  open: boolean;
  state529Connection: State529Connection;
  state529Plan?: State529Plan;
  handlePendingPayments: () => void;
  handleClose: (payload?: Omit<ClosePaymentHandlerPayload, "isFinalStep">) => void;
};

export type ClosePaymentHandlerPayload = {
  isFinalStep?: boolean;
  openWithdrawalModal?: boolean;
  state529PlanProvider?: State529Provider | null;
};

const ExitModalTitle = styled(Typography)({
  fontWeight: "700",
  fontSize: "1.2rem",
  textAlign: "center",
});

const Text = styled(Typography)({
  textAlign: "center",
  margin: "auto",
});

const PaymentModal = ({
  currentUser,
  open,
  state529Connection,
  state529Plan,
  handleClose,
  handlePendingPayments
}: PaymentModalProps) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);

  const attemptToClose = () => setConfirmationModalOpen(true);

  const keepOpen = () => setConfirmationModalOpen(false);

  const closeConfirmation = () => {
    setConfirmationModalOpen(false);
    handleClose();
  };

  const closePaymentHandler = ({ isFinalStep, openWithdrawalModal }: ClosePaymentHandlerPayload) => {
    if (isFinalStep) {
      handlePendingPayments();
      return closeConfirmation();
    }

    if (openWithdrawalModal) {
      setConfirmationModalOpen(false);
      return handleClose({ openWithdrawalModal: true, state529PlanProvider: state529Connection.provider_name });
    }

    attemptToClose();
  };

  return (
    <Modal
      open={open}
      handleClose={attemptToClose}
      dataCy="exit-payment-confirmation-modal"
      confirmation={
        <Dialog open={confirmationModalOpen} onClose={keepOpen}>
          <Stack spacing={3} alignItems="center">
            <ExitModalTitle data-cy="modal-title">Exiting Tuition Payment</ExitModalTitle>
            <Stack alignItems="center">
              <Text>Your payment has not been made yet.</Text>
              <Text>Are you sure you want to leave?</Text>
            </Stack>

            <Button onClick={keepOpen} variant="contained" data-cy="continue-payment">
              Continue Payment
            </Button>
            <Button onClick={closeConfirmation} variant="text" data-cy="payment-exit">
              Exit Payment
            </Button>
          </Stack>
        </Dialog>
      }
    >
      <Payment
        currentUser={currentUser}
        state529Connection={state529Connection}
        state529Plan={state529Plan}
        handleClose={(payload) => closePaymentHandler(payload)}
      />
    </Modal>
  );
};

export default PaymentModal;
