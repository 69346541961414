import { useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

import WarningIcon from "@components/Icons/WarningIcon";
import Modal from "@components/Modal/Modal";

import { InstitutionPaymentStatus } from "@lib/enums/institutionPayment";
import { DEFAULT_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import { getInstitutionPayments } from "@lib/queries/institutionPaymentQueries";

type PendingTuitionPaymentModalProps = {
  open: boolean;
  openTuitionPaymentModal: () => void;
  closeModal: () => void;
}
const logEvent = (message: string) => {
  Sentry.captureMessage(message, "debug");
  console.log("logEvent", message);
};

const PendingTuitionPaymentModal = ({
  open = false,
  openTuitionPaymentModal,
  closeModal
}: PendingTuitionPaymentModalProps) => {
  const { data: pendingTuitionPayments } = useQuery({
    queryKey: [QUERY_KEY.INSTITUTION_PAYMENTS, InstitutionPaymentStatus.PENDING],
    queryFn: ({ queryKey }) => getInstitutionPayments((queryKey[1] as InstitutionPaymentStatus)),
    ...DEFAULT_QUERY_OPTIONS
  });

  const amountText = useMemo(() => {
    if (!pendingTuitionPayments || pendingTuitionPayments.length === 0) {
      return "A Tuition Payment is already set up.";
    }

    return `A Tuition Payment of ${pendingTuitionPayments[0].amount} is already set up.`;
  }, [pendingTuitionPayments]);

  const onProceedAnyways = () => {
    logEvent("PendingTuitionPaymentModal clicked proceed anyways");
    openTuitionPaymentModal();
  };

  const onCancel = () => {
    logEvent("PendingTuitionPaymentModal clicked cancel");
    closeModal();
  };

  useEffect(() => {
    if (open) {
      logEvent("PendingTuitionPaymentModal shown");
      return;
    }
  }, [open]);

  return (
    <Modal
      open={open}
      maxWidth="sm"
      className="sm:rounded-lg"
      data-testid="pending-tuition-payment-modal"
    >
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-warning-icon sm:mx-0 sm:size-10">
            <WarningIcon
              aria-hidden="true"
              className="size-6 text-warning"
              strokeWidth={2}
              outline
            />
          </div>

          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-lg font-semibold text-primary">
              Payment already setup
            </h3>

            <div className="mt-2">
              <p className="text-sm text-gray-600">
                <span data-testid="amount-text">{amountText}</span> It will process automatically once funds are added to your Backpack account from your 529 plan. To avoid duplicate payments, check your Pending Payments on the Dashboard.
                <br /><br />
                Do you want to set up another Tuition Payment?
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-col sm:flex-row sm:justify-end gap-2 sm:gap-3">
        <button
          type="button"
          data-testid="continue-button"
          className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2.5 text-sm font-medium text-primary shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
          onClick={onProceedAnyways}
        >
          Proceed Anyways
        </button>

        <button
          type="button"
          data-testid="cancel-button"
          className="inline-flex w-full justify-center rounded-md bg-yellow-400 text-yellow-900 px-4 py-2.5 text-sm font-medium shadow-sm hover:bg-yellow-400 sm:w-auto"
          data-autofocus
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default PendingTuitionPaymentModal;