import { useShallow } from "zustand/react/shallow";
import { Case, Switch } from "react-if";
import { useNavigate, useParams } from "react-router-dom";

import BackpackLogo from "@assets/images/BackpackLogoHorizontal.svg";

import Add529FundsAccountDetails from "@components/AddFunds/Add529Funds/Add529FundsAccountDetails";
import Add529FundsAmount from "@components/AddFunds/Add529Funds/Add529FundsAmount";
import Add529FundsSubmit from "@components/AddFunds/Add529Funds/Add529FundsSubmit";
import Add529FundsSuccess from "@components/AddFunds/Add529Funds/Add529FundsSuccess";

import { BASE_ROUTE } from "routes/constants";
import { AddFundsSteps, Flow } from "@lib/enums/flows";

import { useAddFundsStore } from "@stores/addFundsStore";
import { useEffect, useMemo } from "react";
import { State529Provider, State529ProviderType } from "@lib/enums/state529";

type Add529FundsProps = {
  onCloseModal?: () => void;
}

const Add529Funds = ({ onCloseModal }: Add529FundsProps) => {
  const navigate = useNavigate();
  const { step } = useParams();

  const currentStep = useAddFundsStore(useShallow((state) => state.step.current));
  const planProviderType = useAddFundsStore(useShallow((state) => state.planProviderType));

  const baseRoute = useMemo(() => {
    const base = `${BASE_ROUTE.dashboard}/${Flow.DIRECT_DEBIT}/`;

    if (planProviderType === State529ProviderType.DIRECT_DEBIT) {
      return base + `${State529Provider.FIDELITY.toLowerCase()}`;
    }

    return null;
  }, [planProviderType]);

  const updateRoute = () => {
    if (!baseRoute) return;

    navigate(`${baseRoute}/${currentStep}`, { replace: true });
  };

  const handleOnFinish = () => {
    if (!onCloseModal) {
      useAddFundsStore.persist.clearStorage();

      // set state to refetch 529 connection on dashboard
      navigate(BASE_ROUTE.dashboard, {
        replace: true,
        state: { refetch529Connection: true }
      });

      return;
    }

    onCloseModal();
  };

  useEffect(() => {
    if (step !== currentStep) {
      updateRoute();
      return;
    }
  }, [currentStep]);

  return (
    <div className="flex flex-col items-center overflow-y-auto sm:h-full">
      <div className="modal-body-scroll flex-col w-[430px]">
        <Switch>
          <Case condition={currentStep === AddFundsSteps.ACCOUNT_DETAILS}>
            <Add529FundsAccountDetails />
          </Case>

          <Case condition={currentStep === AddFundsSteps.AMOUNT}>
            <Add529FundsAmount />
          </Case>

          <Case condition={currentStep === AddFundsSteps.SUBMIT}>
            <Add529FundsSubmit />
          </Case>

          <Case condition={currentStep === AddFundsSteps.SUCCESS}>
            <Add529FundsSuccess onFinish={() => handleOnFinish()} />
          </Case>
        </Switch>
      </div>

      <footer className="my-8 modal-content-fixed justify-center items-center w-full">
        <img
          data-testid="backpack-logo"
          alt="Backpack Logo"
          src={BackpackLogo}
          height={20}
        />
      </footer>
    </div>
  );
};

export default Add529Funds;