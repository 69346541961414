import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import { When } from "react-if";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import PendingIcon from "@mui/icons-material/HistoryToggleOff";
import TransactionIcon from "@mui/icons-material/ReceiptLong";

import DashboardLoadingSkeleton from "@components/LoadingSkeleton/DashboardLoadingSkeleton";
import Card from "commons/Card";
import AccountCard from "@components/AccountCard/AccountCard";
import Transactions from "@components/dashboard/partials/transactions";
import Beneficiary from "@components/dashboard/partials/beneficiary";
import Insights from "@components/Insights";

import Link529Modal from "@components/Link529/Link529Modal";
import PaymentModal, { ClosePaymentHandlerPayload } from "@components/payment/PaymentModal";
import WithdrawalModal from "@components/Withdrawal/WithdrawalModal";
import PendingTuitionPaymentModal from "@components/PendingTuitionPayment/PendingTuitionPaymentModal";
import AddFundsModal from "@components/AddFunds/AddFundsModal";

import {
  DEFAULT_QUERY_OPTIONS,
  FOREVER_STALE_QUERY_OPTIONS,
  QUERY_KEY
} from "@lib/queries/constants";
import { getState529Connection } from "@lib/queries/state529Queries";
import { getTransactions } from "@lib/queries/transactionQueries";
import { getInstitutionPayments } from "@lib/queries/institutionPaymentQueries";

import { useCurrentUserStore } from "@stores/currentUserStore";
import { useAddFundsStore } from "@stores/addFundsStore";

import { LinkType } from "@lib/enums/link529";
import { InstitutionPaymentStatus } from "@lib/enums/institutionPayment";
import { ModalType } from "@lib/enums/modal";
import { WithdrawalStatus } from "@lib/enums/withdrawal";
import { Flow } from "@lib/enums/flows";

import type {
  DashboardModal,
  Link529ModalPayload,
  WithdrawalModalPayload
} from "@lib/types/modal";
import type { State529Connection } from "@lib/types/state529";
import { BASE_ROUTE } from "routes/constants";
import { State529Provider } from "@lib/enums/state529";

const DEFAULT_DASHBOARD_MODAL = { name: null };

const DashboardPage = () => {
  const theme = useTheme();
  const showMobileInsights = useMediaQuery(theme.breakpoints.down("lg"));

  const location = useLocation();
  const navigate = useNavigate();

  const hasCurrentUser = useCurrentUserStore(useShallow((state) => !!state.currentUser.id));
  const hasBeneficiaries = useCurrentUserStore(useShallow((state) => !!state.currentUser.beneficiaries[0].id.length));

  const isOnOnboarding = location.pathname.startsWith("/onboarding");

  // [SM 08/06/2024] - when the components that are using these props are refactored to grab values from the store, we'll be able to remove these
  const currentUser = useCurrentUserStore(useShallow((state) => state.currentUser));
  const bankAccount = useCurrentUserStore(useShallow((state) => state.currentUser.bank_account));
  const beneficiary = useCurrentUserStore(useShallow((state) => !!state.currentUser.beneficiaries && state.currentUser.beneficiaries[0]));
  const state529Plan = useCurrentUserStore(useShallow((state) => !!state.currentUser.beneficiaries && state.currentUser.beneficiaries[0].state_529_plan));
  const state529Plan_id = useCurrentUserStore(useShallow((state) => !!state.currentUser.beneficiaries && state.currentUser.beneficiaries[0].state_529_plan_id));
  const state529Connection = useCurrentUserStore(useShallow((state) => !!state.currentUser.beneficiaries && state.currentUser.beneficiaries[0].state_529_plan.state_529_connection));

  const updateBeneficiary529Connection = useCurrentUserStore((state) => state.updateBeneficiary529Connection);

  const initializeDirectDebitFlow = useAddFundsStore((state) => state.initializeDirectDebitFlow);

  const [modal, setModal] = useState<DashboardModal>(DEFAULT_DASHBOARD_MODAL);

  const {
    data: pendingPayments,
    error: pendingPaymentsError,
    isLoading: isPendingPaymentsLoading,
    isError: isPendingPaymentsError,
    refetch: refetchPendingPayments
  } = useQuery({
    queryKey: [QUERY_KEY.INSTITUTION_PAYMENTS, InstitutionPaymentStatus.PENDING],
    queryFn: ({ queryKey }) => getInstitutionPayments((queryKey[1] as InstitutionPaymentStatus)),
    ...DEFAULT_QUERY_OPTIONS
  });

  const {
    data: transactions,
    error: transactionsError,
    isLoading: isTransactionsLoading,
    isError: isTransactionsError,
    refetch: refetchTransactions,
  } = useQuery({
    queryKey: [QUERY_KEY.TRANSACTIONS],
    queryFn: getTransactions,
    ...DEFAULT_QUERY_OPTIONS
  });

  const {
    data: updatedState529Connection,
    isFetching: isState529ConnectionFetching,
    isSuccess: isState529ConnectionSuccess,
    refetch: refetchState529Connection
  } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_CONNECTION, state529Plan_id],
    queryFn: () => getState529Connection(state529Plan_id),
    enabled: !!state529Plan_id,
    throwOnError: true,
    ...FOREVER_STALE_QUERY_OPTIONS
  });

  const refetch = () => {
    refetchPendingPayments();
    refetchState529Connection();
    refetchTransactions();
  };

  useEffect(() => {
    // for now, if a user ends up on this path
    // without clicking the add 529 funds button
    // redirect to the dashboard
    if (location.pathname.includes("/dashboard/direct-debit") && !modal.name) {
      navigate(BASE_ROUTE.dashboard, { replace: true });
      return;
    }

    if (!location.state) return;

    // when automated link users are coming from onboarding
    if (location.state.openLink529Modal) {
      refetchPendingPayments();
    }

    // open the link 529 modal for automated eligible users
    if (location.state.openLink529Modal && state529Connection.is_automated_withdrawal_eligible) {
      setModal({
        name: ModalType.LINK_529,
        linkType: LinkType.LINK
      });
    }

    // when users coming from onboarding's final screen
    // or when they are coming from the add funds flow
    if (location.state.refetch529Connection) {
      refetchPendingPayments();
      refetchState529Connection();
      refetchTransactions();
    }
  }, [
    location,
    state529Connection
  ]);

  useEffect(() => {
    if (isOnOnboarding
      || !isState529ConnectionSuccess
      || isState529ConnectionFetching
    ) return;

    // update the beneficiary 529 connection if the status has changed
    if (isState529ConnectionSuccess
      && (state529Connection.status !== updatedState529Connection.status)
    ) {
      updateBeneficiary529Connection({
        beneficiary_id: beneficiary.id,
        state_529_connection: updatedState529Connection,
      });
    }
  }, [
    isState529ConnectionFetching,
    isState529ConnectionSuccess
  ]);

  const updateState529Connection = async (update: State529Connection) => {
    // optimistically update the state before refetching from API
    await updateBeneficiary529Connection({
      beneficiary_id: beneficiary.id,
      state_529_connection: update,
    });

    refetchState529Connection();
  };

  const handleOpenAddFundsModal = (flowType: Flow) => {
    if (flowType === Flow.DIRECT_DEBIT) {
      initializeDirectDebitFlow(beneficiary);
      setModal({ name: ModalType.ADD_FUNDS });
    } else {
      setModal({ name: ModalType.WITHDRAWAL });
    }
  };

  const closeLink529Modal = (payload?: Link529ModalPayload) => {
    navigate(location.pathname, { replace: true, state: {} });

    if (payload) {
      const { status, linkType, amount } = payload;

      if (!!linkType && linkType === LinkType.RELINK_WITHDRAWAL) {
        setModal({
          name: ModalType.WITHDRAWAL,
          payload: { amount }
        });

        return;
      }

      if (!!status && status === "LINKED") {
        updateState529Connection({
          ...state529Connection,
          has_linked_before: true,
          status: status as State529Connection["status"]
        });
      }
    }

    setModal(DEFAULT_DASHBOARD_MODAL);
  };

  const closeWithdrawalModal = (payload?: WithdrawalModalPayload) => {
    if (!payload || payload.status === WithdrawalStatus.SUBMITTED) {
      setModal(DEFAULT_DASHBOARD_MODAL);
      return;
    }

    if (payload.status === WithdrawalStatus.FAILED_NEEDS_RELINK) {
      setModal({
        name: ModalType.LINK_529,
        payload: payload,
        linkType: LinkType.RELINK_WITHDRAWAL
      });
    }
  };

  const closePaymentModal = (payload?: Omit<ClosePaymentHandlerPayload, "isFinalStep">) => {
    if (!!payload && payload.state529PlanProvider === State529Provider.FIDELITY) {
      handleOpenAddFundsModal(Flow.DIRECT_DEBIT);
      return;
    }

    if (!!payload && payload.openWithdrawalModal) {
      setModal({ name: ModalType.WITHDRAWAL });
      return;
    }

    setModal(DEFAULT_DASHBOARD_MODAL);
  };

  const handleOpenPayTuitionModal = (openTuitionPaymentModal: boolean) => {
    // only directly open the pay tuition modal
    // when a user has no pending payments
    if (openTuitionPaymentModal || !pendingPayments || pendingPayments.length === 0) {
      setModal({ name: ModalType.PAY_TUITION });
      return;
    }

    setModal({ name: ModalType.PENDING_TUITION_PAYMENT });
  };

  const closeAddFundsModal = () => {
    setModal(DEFAULT_DASHBOARD_MODAL);
    refetch();
  };

  if (hasCurrentUser && !bankAccount.id) {
    throw new Error("Missing account information");
  }

  // [SM - 09/29/2024]: update this to show the error in a toast
  if (isPendingPaymentsError || isTransactionsError) {
    const error_message = pendingPaymentsError || transactionsError;

    console.error(error_message);
  }

  if (!hasCurrentUser
    || isPendingPaymentsLoading
    || isTransactionsLoading
  ) return <DashboardLoadingSkeleton />;

  return (
    <>
      <div className="flex flex-col lg:flex-row size-full max-w-7xl xl:mx-auto gap-4 xl:gap-8 p-6 xl:p-8 overflow-y-scroll bg-white">
        <h1 className="sr-only">Dashboard</h1>

        <main className="flex flex-col flex-1 gap-6 min-w-0 h-fit pb-8">
          <AccountCard data-cy="account-component" />

          {hasBeneficiaries &&
            <Beneficiary
              data-cy="beneficiary-component"
              beneficiary={beneficiary}
              state529Connection={state529Connection}
              openLink529Modal={(linkType) => setModal({ name: ModalType.LINK_529, linkType })}
              openPayTuitionModal={() => handleOpenPayTuitionModal(false)}
              openWithdrawalModal={() => setModal({ name: ModalType.WITHDRAWAL })}
              openAddFundsModal={(flowType) => handleOpenAddFundsModal(flowType)}
            />
          }

          <When condition={showMobileInsights}>
            <Insights profiles={{ bankAccount, beneficiary, state529Plan }} />
          </When>

          <h2 className="font-normal text-lg">Transactions</h2>

          {/* Note [Facundo 5/24]: We can set it open by default if we have pending payments */}
          <Card
            title="Pending Payments"
            icon={<PendingIcon />}
            defaultOpen
          >
            <Transactions transactions={pendingPayments} variant="payments" />
          </Card>

          <Card
            title="Recent Transactions"
            icon={<TransactionIcon />} defaultOpen
          >
            <Transactions transactions={transactions} />
          </Card>
        </main>

        <aside className="flex flex-col pb-8 min-w-0 xl:shrink-0">
          <When condition={!showMobileInsights}>
            <Insights profiles={{ bankAccount, beneficiary, state529Plan }} />
          </When>
        </aside>
      </div>

      <Link529Modal
        bankAccount={bankAccount}
        beneficiary={beneficiary}
        state529Plan={state529Plan}
        open={modal.name === ModalType.LINK_529}
        reLink={{ linkType: modal.linkType, payload: modal.payload }}
        handleOnClose={(payload) => closeLink529Modal(payload)}
      />

      <PaymentModal
        currentUser={currentUser}
        state529Connection={state529Connection}
        state529Plan={state529Plan}
        open={modal.name === ModalType.PAY_TUITION}
        handlePendingPayments={refetchPendingPayments}
        handleClose={(payload) => closePaymentModal(payload)}
      />

      <WithdrawalModal
        beneficiary={beneficiary}
        state529Plan={state529Plan}
        amount={modal.payload?.amount}
        open={modal.name === ModalType.WITHDRAWAL}
        handleClose={(payload) => closeWithdrawalModal(payload)}
      />

      <PendingTuitionPaymentModal
        open={modal.name === ModalType.PENDING_TUITION_PAYMENT}
        openTuitionPaymentModal={() => handleOpenPayTuitionModal(true)}
        closeModal={() => setModal(DEFAULT_DASHBOARD_MODAL)}
      />

      <AddFundsModal
        open={modal.name === ModalType.ADD_FUNDS}
        onAddFundsCompleted={() => closeAddFundsModal()}
        closeModal={() => setModal(DEFAULT_DASHBOARD_MODAL)}
      />
    </>
  );
};

export default DashboardPage;