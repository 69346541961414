import { useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import {
  useQueries,
  useQuery,
  type UseQueryResult
} from "@tanstack/react-query";

import { DEFAULT_529_PLAN_CONNECTION } from "@lib/constants/currentUser";

import { ProfileType } from "@lib/enums/userProfile";
import { State529ConnectionStatus } from "@lib/enums/state529";

import { checkProfileStatus } from "@helpers/checkProfileStatus";
import { getState529PlanType } from "@helpers/getState529PlanDetails";

import {
  DEFAULT_QUERY_OPTIONS,
  FOREVER_STALE_QUERY_OPTIONS,
  QUERY_KEY,
  STALE_TIME_5_MINS
} from "@lib/queries/constants";
import { getBeneficiaries } from "@lib/queries/beneficiaryQueries";
import { getInstitutionPayments } from "@lib/queries/institutionPaymentQueries";
import { getTransactions } from "@lib/queries/transactionQueries";
import { getState529Connection } from "@lib/queries/state529Queries";

import { useUserProfileStore } from "@stores/userProfileStore";

import type { Beneficiary } from "@lib/types/beneficiary";
import type { InstitutionPayment } from "@lib/types/institutionPayment";
import type { Transaction } from "@lib/types/transaction";

export const useBuildUserProfiles = (enable: boolean) => {
  const userProfiles = useUserProfileStore(useShallow((state) => state.profiles));
  const userProfileStatus = useUserProfileStore(useShallow((state) => state.statusComplete));

  const updatedProfiles = { ...userProfiles };
  const updateProfileStatus = { ...userProfileStatus };

  const combinedQueries = useQueries({
    queries: [{
      queryKey: [QUERY_KEY.BENEFICIARIES],
      queryFn: getBeneficiaries,
      enabled: enable,
      throwOnError: true,
      staleTime: STALE_TIME_5_MINS,
      ...DEFAULT_QUERY_OPTIONS,
    },
    {
      queryKey: [QUERY_KEY.INSTITUTION_PAYMENTS],
      queryFn: () => getInstitutionPayments(),
      enabled: enable,
      throwOnError: true,
      ...FOREVER_STALE_QUERY_OPTIONS,
    },
    {
      queryKey: [QUERY_KEY.TRANSACTIONS],
      queryFn: getTransactions,
      enabled: enable,
      throwOnError: true,
      ...FOREVER_STALE_QUERY_OPTIONS,
    }],
    combine: useCallback((results: UseQueryResult[]) => {
      const beneficiaries = results[0].data as Beneficiary[];
      const institutionPayments = results[1].data as InstitutionPayment[];
      const transactions = results[2].data as Transaction[];

      const hasBeneficiary = !!beneficiaries && beneficiaries.length >= 1;
      const hasInstitutionPayments = !!institutionPayments && institutionPayments.length >= 1;
      const hasTransactions = !!transactions && transactions.length >= 1;

      const state_529_plan_id = hasBeneficiary ? beneficiaries[0].state_529_plan_id : "";

      return {
        data: {
          hasBeneficiary,
          hasInstitutionPayments,
          hasTransactions,
          state_529_plan_id
        },
        isSuccess: results.every((result) => result.isSuccess),
      };
    }, []),
  });

  const {
    data,
    isSuccess
  } = combinedQueries;

  const state_529_plan_id = useMemo(() => data?.state_529_plan_id, [data?.state_529_plan_id]);

  const {
    data: state_529_connection,
    isFetching: isState529ConnectionFetching,
    isFetched: isState529ConnectionFetched,
  } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_CONNECTION, state_529_plan_id],
    queryFn: () => getState529Connection(state_529_plan_id),
    enabled: enable && !!state_529_plan_id,
    throwOnError: true,
    staleTime: STALE_TIME_5_MINS,
    ...DEFAULT_QUERY_OPTIONS
  });

  const isAllDataFetched = useMemo(() => {
    if (!isSuccess
      || (!!state_529_plan_id.length &&!state_529_connection)
      || !enable
    ) {
      return false;
    }

    return true;
  }, [
    enable,
    isSuccess,
    state_529_plan_id,
    isState529ConnectionFetching,
    isState529ConnectionFetched,
    state_529_connection
  ]);

  if (!isAllDataFetched) return null;

  if (isAllDataFetched) {
    const {
      hasBeneficiary,
      hasInstitutionPayments,
      hasTransactions
    } = data;

    // set the state 529 connection if it exists
    const state529Connection = !!state_529_plan_id.length && state_529_connection
      ? state_529_connection
      : { ...DEFAULT_529_PLAN_CONNECTION };

    const {
      planProviderType,
      planType,
    } = getState529PlanType({
      state_529_plan_id,
      state_529_connection: state529Connection
    });

    const onboardingProfileUpdate = {
      hasBeneficiary,
      hasInstitutionPayments,
      hasTransactions,
      planProviderType,
      planType,
      hasSkippedTuitionPayment: null,
      hasSkippedManualLinking: null,
      hasSkippedDirectDebitLinking: null,
      hasLinked529: state529Connection.status !== State529ConnectionStatus.NOT_LINKED,
    };

    updatedProfiles.onboarding = onboardingProfileUpdate;
    updateProfileStatus.onboarding = checkProfileStatus(
      ProfileType.ONBOARDING,
      onboardingProfileUpdate
    );
  }

  return {
    profiles: updatedProfiles,
    statusComplete: updateProfileStatus
  };
};