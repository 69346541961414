/**
 * Default date format options for formatting dates.
 * Outputs dates in the format "MMM DD, YYYY" (e.g., "Nov 15, 2024").
 */
const DEFAULT_DATE_FORMAT_OPTIONS = {
  month: "short",
  day: "numeric",
  year: "numeric"
} as Intl.DateTimeFormatOptions;

/**
 * Formats a date string into a locale-aware string based on provided options.
 *
 * @param {string | undefined} date - The date string to format. Must be in a format parsable by the `Date` constructor.
 * @param {Intl.DateTimeFormatOptions} [options=DEFAULT_DATE_FORMAT_OPTIONS] - Optional formatting options to customize the output.
 * @returns {string} - The formatted date string, or an empty string if the input is invalid or undefined.
 *
 * @example
 * formatDateString("2024-11-15"); // "Nov 15, 2024"
 * formatDateString("2024-11-15", { year: "2-digit", month: "numeric", day: "2-digit" }); // "11/15/24"
 */
export function formatDateString(
  date: string | undefined,
  options: Intl.DateTimeFormatOptions | undefined = DEFAULT_DATE_FORMAT_OPTIONS
): string {
  if (!date) return "";

  const newDate = new Date(date);

  return newDate.toLocaleDateString("en-US", options);
}

/**
 * Formats a date string into the "DD-MMM-YYYY" format (e.g., "15-Nov-2024").
 *
 * @param {string | undefined} date - The date string to format. Must be in a format parsable by the `Date` constructor.
 * @returns {string} - The formatted date string in "DD-MMM-YYYY" format, or an empty string if the input is invalid or undefined.
 *
 * @example
 * formatDateStringToShortMonth("2024-11-22T10:30:00Z"); // "22-Nov-2024"
 * formatDateStringToShortMonth("invalid-date"); // ""
 */
export function formatDateStringToShortMonth(
  date: string | undefined
): string {
  if (!date) return "";

  const newDate = new Date(date);

  if (isNaN(newDate.getTime())) return "";

  const parts = newDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  }).split(" ");

  const [month, day, year] = parts; // e.g., ["Nov", "15,", "2024"]
  return `${day.replace(",", "")}-${month}-${year}`;
}

export default {
  formatDateString,
  formatDateStringToShortMonth
};