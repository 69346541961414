import { Flow, OnboardingSteps } from "@lib/enums/flows";

import type { OnboardingStepperVariants } from "@lib/types/onboarding";

export const ONBOARDING_STEP = {
  [OnboardingSteps.WELCOME]: {
    step: 1,
    name: OnboardingSteps.WELCOME,
    title: "Welcome to Backpack!",
    description: "Get up and running in 3 mins",
  },
  [OnboardingSteps.STUDENT_DETAILS]: {
    step: 2,
    name: OnboardingSteps.STUDENT_DETAILS,
    title: "Add student details",
    description: "Enter your 529's beneficiary details",
  },
  [OnboardingSteps.CONFIRM_ATTENDANCE]: {
    step: 3,
    name: OnboardingSteps.CONFIRM_ATTENDANCE,
    title: "Confirm attendance",
    description: "Let your University know that you are paying with your 529",
  },
  [OnboardingSteps.LINK_529]: {
    step: 4,
    name: OnboardingSteps.LINK_529,
    title: "Complete your payment",
    description: "Withdraw 529 funds to Backpack",
  },
  [OnboardingSteps.FINAL_SCREEN]: {
    name: OnboardingSteps.FINAL_SCREEN,
  },
  [OnboardingSteps.DONE]: {
    name: OnboardingSteps.DONE
  }
};

export const ONBOARDING_DESCRIPTION_VARIANT: OnboardingStepperVariants = {
  [OnboardingSteps.STUDENT_DETAILS]: {
    completed: {
      description: "Beneficiary successfully added"
    },
  },
  [OnboardingSteps.CONFIRM_ATTENDANCE]: {
    created: {
      description: "Tuition payment setup",
    },
    skipped: {
      description: "Skipped for now"
    },
  },
  [OnboardingSteps.LINK_529]: {
    skipped: {
      title: "Link your 529 to Backpack",
      description: "Connect your 529 for seamless payments",
    },
    skipped_linking: {
      description: "529 Linking skipped for now"
    },
    completed: {
      description: "529 Linked and awaiting withdrawal"
    }
  }
};

export const TUITION_PAYMENT_CREATED_STEPS = [
  ONBOARDING_STEP[OnboardingSteps.WELCOME],
  {
    ...ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS],
    description: ONBOARDING_DESCRIPTION_VARIANT[OnboardingSteps.STUDENT_DETAILS].completed.description,
  },
  {
    ...ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE],
    description: ONBOARDING_DESCRIPTION_VARIANT[OnboardingSteps.CONFIRM_ATTENDANCE].created.description,
  },
  ONBOARDING_STEP[OnboardingSteps.LINK_529]
];

export const DEFAULT_STEPS = [
  ONBOARDING_STEP[OnboardingSteps.WELCOME],
  ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS],
  ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE],
  ONBOARDING_STEP[OnboardingSteps.LINK_529],
];

export const DEFAULT_INITIAL_STATE = {
  step: null,
  stepCount: 0,
  steps: [...DEFAULT_STEPS],
  nestedFlow: null,
  status: {
    [Flow.TUITION_PAYMENT]: null,
    [Flow.LINK_529]: null,
  },
  state529PlanDetails: {
    state529Plan: null,
    planProviderType: null,
    account_number: null,
    routing_number: null,
  },
  origin: null,
  tuitionPaymentPayload: null,
};