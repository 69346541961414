import instance from "config/fetch.config";
import { QueryError, MutationError } from "@utils/errors";

import type { UUID } from "@lib/types/global";
import type { CreateInstitutionPaymentPayload, InstitutionPayment } from "@lib/types/institutionPayment";
import type { State529Plan } from "@lib/types/state529";
import type { TuitionPaymentConfirmation } from "@stores/tuitionPaymentStore";

type CreateInstitutionPaymentToken = {
  mfa_code: string;
  institution_id: UUID;
}

export const createInstitutionPaymentToken = async (payload: CreateInstitutionPaymentToken): Promise<string> => {
  try {
    const { data: { data } } = await instance.post("/institution-payments/tokens", payload);

    return data.token;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

export const requestInstitutionPaymentMFACode = async () => {
  try {
    await instance.post("/institution-payments/mfa-codes");
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

export const createInstitutionPayment = async (payload: CreateInstitutionPaymentPayload) => {
  try {
    const { data: { data } } = await instance.post("/institution-payments", payload);

    return data.institution_payment;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

export const debitInstitutionPayment = async ({
  institution_payment_id,
  state_529_plan_id
}: {
  institution_payment_id: InstitutionPayment["id"];
  state_529_plan_id: State529Plan["id"]
}): Promise<TuitionPaymentConfirmation> => {
  try {
    const url = `/institution-payments/${institution_payment_id}/debit`;
    const { data: { data } } = await instance.post(url, { state_529_plan_id });

    return data.withdrawal_request;
  } catch (error: any) {
    throw new MutationError(error.response.data);
  }
};