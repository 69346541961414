import { create } from "@stores/utils";
import { persist, createJSONStorage } from "zustand/middleware";

import { VERIFICATION_TYPE } from "@components/Link529/Link529Manual/constants";
import { DEFAULT_GUIDE_LINK } from "@lib/constants/link529";

import { ManualLink529Steps } from "@lib/enums/flows";
import { State529ProviderType } from "@lib/enums/state529";

import { getState529SupportDetails } from "@helpers/getState529PlanDetails";

import type { ManualLinkType, State529Plan } from "@lib/types/state529";

type Link529State = {
  providerType: State529ProviderType;
  guides: {
    linking: string;
    withdrawal: string;
  };
  planType: ManualLinkType | null;
  step: {
    current: ManualLink529Steps | null;
    prev: ManualLink529Steps | null;
  };
  screen: {
    current: ManualLink529Steps | null;
    prev: ManualLink529Steps | null;
  };
  status: ManualLink529Steps.SKIPPED | null;
};

type Link529Action = {
  initializeLink529Store: (state_529_plan_id: State529Plan["id"]) => void;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  showSkipManualLinkingDialog: () => void;
  setGuideLinks: (state_529_plan_id: State529Plan["id"]) => void;
  reset: () => void;
};

const DEFAULT_INITIAL_STATE = {
  providerType: State529ProviderType.MANUAL,
  planType: null,
  guides: {
    linking: "#",
    withdrawal: "#",
  },
  step: {
    current: ManualLink529Steps.MANUAL,
    prev: null
  },
  screen: {
    current: ManualLink529Steps.MANUAL,
    prev: null
  },
  status: null,
};

export const useLink529Store = create<Link529State & Link529Action>()(
  persist(
    (set, get) => ({
      ...DEFAULT_INITIAL_STATE,

      initializeLink529Store: (state_529_plan_id) => {
        const details = getState529SupportDetails(state_529_plan_id);

        const planType = (details?.verification_type as ManualLinkType) || null;

        // add default withdrawal and linking guide
        const updatedGuideLinks = {
          linking: DEFAULT_GUIDE_LINK,
          withdrawal: DEFAULT_GUIDE_LINK
        };

        if (details) {
          updatedGuideLinks.linking = details.linking_guide;
          updatedGuideLinks.withdrawal = details.withdrawal_guide;
        }

        if (details?.verification_type === VERIFICATION_TYPE.DIRECT_PAY) {
          set((state) => ({
            ...state,
            planType,
            guides: { ...updatedGuideLinks },
            step: {
              current: ManualLink529Steps.WITHDRAWAL,
              prev: null,
            },
            screen: {
              current: ManualLink529Steps.WITHDRAWAL,
              prev: null,
            },
          }));

          return;
        }

        set((state) => ({
          ...state,
          planType,
          guides: { ...updatedGuideLinks },
        }));
      },

      goToNextStep: () => {
        const currentStep = get().step.current;
        const planType = get().planType;

        let nextStep: (ManualLink529Steps | null) = null;
        let previousStep: (ManualLink529Steps | null) = null;

        const status = null;

        switch (currentStep) {
          case ManualLink529Steps.MANUAL:
            if (planType === VERIFICATION_TYPE.PLAID_LINK) {
              nextStep = ManualLink529Steps.WITHDRAWAL;
              previousStep = ManualLink529Steps.MANUAL;
            } else {
              nextStep = ManualLink529Steps.VERIFICATION;
              previousStep = ManualLink529Steps.MANUAL;
            }
            break;

          case ManualLink529Steps.VERIFICATION:
            nextStep = ManualLink529Steps.WITHDRAWAL;
            previousStep = ManualLink529Steps.VERIFICATION;
            break;

          case ManualLink529Steps.WITHDRAWAL:
            nextStep = ManualLink529Steps.DONE;
            previousStep = ManualLink529Steps.WITHDRAWAL;
            break;
        }

        set({
          step: {
            current: nextStep,
            prev: previousStep
          },
          screen: {
            current: nextStep,
            prev: previousStep
          },
          status
        });
      },

      goToPrevStep: () => {
        const currentStep = get().step.current;
        const planType = get().planType;

        let newCurrentStep: (ManualLink529Steps | null) = null;
        let previousStep: (ManualLink529Steps | null) = null;

        const status = null;

        switch (currentStep) {
          case ManualLink529Steps.SKIPPED:
            newCurrentStep = ManualLink529Steps.MANUAL;
            previousStep = ManualLink529Steps.MANUAL;
            break;

          case ManualLink529Steps.VERIFICATION:
            newCurrentStep = ManualLink529Steps.MANUAL;
            previousStep = ManualLink529Steps.MANUAL;
            break;

          case ManualLink529Steps.WITHDRAWAL:
            if (planType === VERIFICATION_TYPE.DIRECT_PAY) {
              newCurrentStep = null;
              previousStep = null;
            } else if (planType === VERIFICATION_TYPE.PLAID_LINK) {
              newCurrentStep = ManualLink529Steps.MANUAL;
              previousStep = null;
            } else {
              newCurrentStep = ManualLink529Steps.VERIFICATION;
              previousStep = ManualLink529Steps.MANUAL;
            }
            break;
        }

        set({
          step: {
            current: newCurrentStep,
            prev: previousStep
          },
          screen: {
            current: newCurrentStep,
            prev: previousStep
          },
          status
        });
      },

      showSkipManualLinkingDialog: () => {
        set((state) => ({
          ...state,
          step: {
            current: ManualLink529Steps.SKIPPED,
            prev: ManualLink529Steps.MANUAL,
          },
          screen: {
            current: ManualLink529Steps.MANUAL,
            prev: ManualLink529Steps.MANUAL,
          },
          status: null
        }));
      },

      setGuideLinks: (state_529_plan_id) => {
        const details = getState529SupportDetails(state_529_plan_id);

        if (!details) return;

        set({
          guides: {
            linking: details.linking_guide,
            withdrawal: details.withdrawal_guide
          }
        });
      },

      reset: () => {
        set(DEFAULT_INITIAL_STATE);
      },
    }),
    {
      name: "linking",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);