import React from "react";

import Icon from "@components/Icons/Icon";
import type { OutlineIconProps } from "components/Icons/Icon";

/**
 * The WarningIcon is called exclamation-triangle on Hero Icons
 */
const WarningIcon = React.memo(function WarningIcon({
  outline = false,
  ...iconProps
}: OutlineIconProps) {
  return (
    <Icon outline={outline} { ...iconProps }>
      {outline ?
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
        :
        <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
      }
    </Icon>
  );
});

export default WarningIcon;
