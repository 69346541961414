/**
 * Returns the last 4 digits of a given string.
 * If the string has fewer than 4 digits, it returns the entire string.
 *
 * @param input - The input string to process.
 * @returns The last 4 digits of the string.
 */
export function getLastFourDigits(input: string): string {
  if (!input) return "";

  // Remove non-digit characters and take the last 4 digits
  const digits = input.replace(/\D/g, "");
  return digits.slice(-4);
}
