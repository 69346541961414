import {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useQuery } from "@tanstack/react-query";

import Button from "@components/Button/Button";
import Spinner from "@components/Spinner/Spinner";
import AddBeneficiaryForm from "@components/AddBeneficiary/AddBeneficiaryForm";

import { ProfileType } from "@lib/enums/userProfile";
import { State529Provider } from "@lib/enums/state529";

import { getState529PlanType } from "@helpers/getState529PlanDetails";
import { useFormatBeneficiaries } from "@hooks/useFormatBeneficiaries";

import { DEFAULT_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import { getState529Connection } from "@lib/queries/state529Queries";

import { useCurrentUserStore } from "@stores/currentUserStore";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useOnboardingStore } from "@stores/onboardingStore";

import type { Beneficiary, BeneficiaryResponse } from "@lib/types/beneficiary";

const StudentDetailsScreen = () => {
  const formId = "onboarding-student-details-form";
  const formRef = useRef<HTMLFormElement | null>(null);

  // setting the formId
  useEffect(() => {
    if (formRef.current && formRef.current.id !== formId) {
      formRef.current.id = formId;
    }
  }, [formId]);

  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isPendingSubmit, setIsPendingSubmit] = useState<boolean>(false);

  const [beneficiaries, setBeneficiaries] = useState<BeneficiaryResponse[]>([]);

  const setInitialBeneficiaries = useCurrentUserStore((state) => state.setBeneficiaries);
  const updateProfile = useUserProfileStore((state) => state.updateProfile);
  const updateState529PlanDetails = useOnboardingStore((state) => state.updateState529PlanDetails);

  const goToNextStep = useOnboardingStore((state) => state.goToNextStep);
  const goToAdd529Details = useOnboardingStore((state) => state.goToAdd529Details);

  const state_529_plan_id = useMemo(() => {
    if (!!beneficiaries
      && !!beneficiaries.length
      && !!beneficiaries[0].state_529_plan_id
    ) return beneficiaries[0].state_529_plan_id;

    return "";
  }, [beneficiaries]);

  const {
    refetch: refetchState529Connection,
    isSuccess: isState529ConnectionSuccess
  } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_CONNECTION, state_529_plan_id],
    queryFn: () => getState529Connection(state_529_plan_id),
    throwOnError: true,
    enabled: !!beneficiaries && !!beneficiaries.length && !!state_529_plan_id,
    ...DEFAULT_QUERY_OPTIONS
  });

  const {
    formattedBeneficiaries,
    isFetching: isFormattedBeneficiariesFetching,
  } = useFormatBeneficiaries({
    beneficiaries,
    gcTime: 0,
    enable: isState529ConnectionSuccess
      && !!beneficiaries
      && !!beneficiaries.length,
  });

  const handleIsValidForm = (isValid: boolean) => {
    if (isValid === isValidForm) return;

    setIsValidForm(isValid);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleBeneficiaryUpdate = (updatedBeneficiaries: Beneficiary[]) => {
    if (!updatedBeneficiaries) {
      throw new Error("Missing beneficiary");
    }

    const {
      state_529_plan_id,
      state_529_plan: { state_529_connection }
    } = updatedBeneficiaries[0];

    // get the 529 plan's plan provider type and plan type
    const {
      planProviderType,
      planType
    } = getState529PlanType({
      state_529_plan_id,
      state_529_connection,
    });

    // set the beneficiary in the store
    setInitialBeneficiaries(updatedBeneficiaries);

    // update the onboarding profile
    updateProfile({
      type: ProfileType.ONBOARDING,
      update: {
        planProviderType,
        planType,
        hasBeneficiary: true
      }
    });

    updateState529PlanDetails({
      state529Plan: { ...updatedBeneficiaries[0].state_529_plan },
      planProviderType,
    });

    if (planType === State529Provider.FIDELITY) {
      goToAdd529Details();
    } else {
      goToNextStep();
    }
  };

  // refetch the state529connection
  useEffect(() => {
    if (!state_529_plan_id.length) return;

    if (state_529_plan_id.length
      && !formattedBeneficiaries
      && !isFormattedBeneficiariesFetching
    ) {
      refetchState529Connection();
    }
  }, [state_529_plan_id]);

  // after the beneficiary is successfully added and formatted
  // update the beneficiary in the store
  useEffect(() => {
    if (!beneficiaries && !formattedBeneficiaries) return;

    if (formattedBeneficiaries) {
      handleBeneficiaryUpdate(formattedBeneficiaries);
    }
  }, [formattedBeneficiaries]);

  return (
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title" data-testid="onboarding-screen-header">Add Student Details</h2>

        <p className="modal-header-description" data-testid="onboarding-screen-description">Let&#39;s add the 529 beneficiary whose education you&#39;re supporting. You&#39;ll be able to add more beneficiaries later too.</p>

        <AddBeneficiaryForm
          ref={formRef}
          formId={formId}
          hideSubmitButton={true}
          onIsValidForm={(isValid) => handleIsValidForm(isValid)}
          onPendingSubmit={(enable) => setIsPendingSubmit(enable)}
          onFormSuccess={(beneficiary) => setBeneficiaries([beneficiary])}
        />
      </div>

      <div className="w-full shrink-0">
        <Button
          type="submit"
          form={formId}
          data-testid="student-details-submit-btn"
          data-gtm="onboarding-add-student"
          disabled={!isValidForm || isPendingSubmit}
          rounded
          fullWidth
          onClick={handleFormSubmit}
        >
          {isPendingSubmit
            ? <Spinner color="white" />
            : "Continue"
          }
        </Button>
      </div>
    </div>
  );
};

export default StudentDetailsScreen;