export const MUTATION_KEY = {
  CREATE_BENEFICIARY: "create-beneficiary",
  UPDATE_BENEFICIARY_ENROLLMENT: "update-beneficiary-enrollment",
  CREATE_INSTITUTION_PAYMENT_TOKEN: "create-institution-payment-token",
  REQUEST_INSTITUTION_PAYMENT_CODE: "request-institution-payment-code",
  CREATE_INSTITUTION_PAYMENT: "create-institution-payment",
  UPDATE_MANUAL_LINK: "update-manual-link",
  ADD_529_COUNTERPARTY_LINK: "add-529-counterparty-link",
  DEBIT_INSTITUTION_PAYMENT: "debit-institution-payment",
  DEBIT_WITHDRAWAL_REQUEST: "debit-withdrawal-request"
};