import { When } from "react-if";
import { useShallow } from "zustand/react/shallow";
import { useNavigate } from "react-router-dom";

import collegeGraduation from "@assets/images/collegeGraduation.svg";
import LinkIcon from "@components/Icons/LinkIcon";
import ShieldExclamationIcon from "@components/Icons/ShieldExclamationIcon";
import CheckCircleIcon from "@components/Icons/CheckCircleIcon";
import Button from "@components/Button/Button";

import { Flow, TuitionPaymentSteps } from "@lib/enums/flows";
import { BASE_ROUTE } from "routes/constants";

import { useOnboardingStore } from "@stores/onboardingStore";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useLink529Store } from "@stores/link529Store";

import { classnames } from "@utils/classnames";
import { State529ProviderType } from "@lib/enums/state529";
import AcademicCapIcon from "@components/Icons/AcademicCapIcon";
import { useMemo } from "react";

const FinalScreen = () => {
  const navigate = useNavigate();

  const hasSkippedLinking = useOnboardingStore(useShallow((state) => state.status[Flow.LINK_529]));
  const hasSkippedTuitionPayment = useOnboardingStore(useShallow((state) => state.status[Flow.TUITION_PAYMENT] === TuitionPaymentSteps.SKIPPED));
  const planProviderType = useUserProfileStore(useShallow((state) => state.profiles.onboarding.planProviderType));

  const updateProfileStatus = useUserProfileStore((state) => state.updateProfileStatus);

  const closeOnboarding = () => {
    // reset link 529 store to default state
    // update profile status to onboarding is complete
    useLink529Store.persist.clearStorage();
    updateProfileStatus({ onboarding: true });

    // set state to refetch 529 connection on dashboard
    navigate(BASE_ROUTE.dashboard, {
      replace: true,
      state: { refetch529Connection: true }
    });
  };

  const showVariant = useMemo(() => {
    if (hasSkippedLinking || hasSkippedTuitionPayment) return true;

    return false;
  }, [hasSkippedLinking, hasSkippedTuitionPayment]);

  return (
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title">
          {showVariant
            ? "Class dismissed"
            : "Class dismissed, you're all set!"
          }
        </h2>

        <p className="modal-header-description mt-4">
          {showVariant
            ? "Your account is all set but you've got a little homework to do. Complete the steps below to ensure your upcoming tuition payments aren't delayed."
            : "Head to your Backpack dashboard to track your payments, setup additional students, manage your 529 expenses and much more."
          }
        </p>

        <img
          src={collegeGraduation}
          alt="students throwing graduation caps"
          loading="lazy"
          height={showVariant ? 185 : 246}
          width={showVariant ? 300 : 400}
          className={classnames("h-auto w-full max-w-60", {
            "mt-1.5 sm:max-w-[300px]": showVariant,
            "mt-10 sm:mt-16 sm:max-w-[400px]": !showVariant
          })}
        />

        <When condition={hasSkippedLinking && planProviderType !== State529ProviderType.DIRECT_DEBIT}>
          <ul className="flex flex-col gap-6 pt-4 text-sm max-w-[350px]">
            <li className="flex gap-4">
              <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                <LinkIcon outline strokeWidth={2} aria-hidden="true" />
              </span>

              <p><strong>Link your 529.</strong> Doing this ASAP will ensure there&apos;s no delays in paying your tuition bill.</p>
            </li>

            <li className="flex gap-4">
              <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                <ShieldExclamationIcon outline strokeWidth={2} aria-hidden="true" />
              </span>

              <p><strong>Follow your 529 plan&apos;s verification steps.</strong> Some 529 plans have a hold period before funds can be withdrawn to new accounts.</p>
            </li>

            <li className="flex gap-4">
              <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                <CheckCircleIcon outline strokeWidth={2} aria-hidden="true" />
              </span>

              <p><strong>Withdraw 529 funds</strong> to your Backpack account to cover tuition and eligible expenses like textbooks, laptops and more.</p>
            </li>
          </ul>
        </When>

        <When condition={planProviderType === State529ProviderType.DIRECT_DEBIT && showVariant}>
          <ul className="flex flex-col gap-6 pt-4 text-sm max-w-[350px]">
            <li className="flex gap-4">
              <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                <LinkIcon outline strokeWidth={2} aria-hidden="true" />
              </span>

              <p><strong>Add 529 account details and funds.</strong> Your direct debit details are required to make Tuition Payments and withdraw 529 funds for non-tuition expenses like laptops, textbooks and more.</p>
            </li>

            <li className="flex gap-4">
              <span className="inline-flex size-8 items-center justify-center rounded-full bg-sky-100 shrink-0">
                <AcademicCapIcon outline strokeWidth={2} aria-hidden="true" />
              </span>

              <p><strong>Pay Tuition.</strong> When you receive your tuition bill click “Pay Tuition” from your Backpack dashboard.</p>
            </li>
          </ul>
        </When>
      </div>

      <div className="w-full shrink-0">
        <Button
          rounded
          fullWidth
          onClick={closeOnboarding}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default FinalScreen;