import { useMemo, useRef, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { When } from "react-if";

import Button from "@components/Button/Button";
import Spinner from "@components/Spinner/Spinner";
import Stepper from "@components/Stepper/Stepper";

import DirectDebitAccountForm from "@components/DirectDebit/DirectDebitAccountForm";

import { useAddFundsStore } from "@stores/addFundsStore";
import { useCurrentUserStore } from "@stores/currentUserStore";

import { ADD_FUNDS_STEP_DETAILS } from "@lib/constants/addFunds";
import { AddFundsSteps } from "@lib/enums/flows";
import { State529ConnectionStatus, State529ProviderType } from "@lib/enums/state529";
import type { AccountDetails } from "@lib/types/addFunds";

const Add529FundsAccountDetails = () => {
  const formId = "onboarding-fidelity-account-form";
  const formRef = useRef<HTMLFormElement | null>(null);

  const step = useAddFundsStore(useShallow((state) => state.step));

  const beneficiary_id = useAddFundsStore(useShallow((state) => state.beneficiary?.id || ""));
  const state529Connection = useAddFundsStore(useShallow((state) => state.state529Plan?.state_529_connection));

  const update529Connection = useCurrentUserStore((state) => state.updateBeneficiary529Connection);

  const updateLink529Success = useAddFundsStore((state) => state.updateLink529Success);
  const goToNextStep = useAddFundsStore((state) => state.goToNextStep);

  const details = useMemo(() => {
    if (!step.details) return ADD_FUNDS_STEP_DETAILS[State529ProviderType.DIRECT_DEBIT][AddFundsSteps.ACCOUNT_DETAILS];

    return step.details[AddFundsSteps.ACCOUNT_DETAILS];
  }, [step]);

  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isPendingSubmit, setIsPendingSubmit] = useState<boolean>(false);

  const handleIsValidForm = (isValid: boolean) => {
    if (isValid === isValidForm) return;

    setIsValidForm(isValid);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleOnFormSuccess = (accountDetails: AccountDetails) => {
    const state529ConnectionUpdate = {
      status: State529ConnectionStatus.LINKED,
      has_linked_before: true,
      is_withdrawal_eligible: true
    };

    // optimistic update the currentUser store
    if (state529Connection) {
      update529Connection({
        beneficiary_id: beneficiary_id,
        state_529_connection: {
          ...state529Connection,
          ...state529ConnectionUpdate
        }
      });
    }

    // update the addFunds store
    updateLink529Success(state529ConnectionUpdate, accountDetails.account_number);

    goToNextStep();
  };

  return (
    <>
      <header className="modal-content-fixed flex-col px-12">
        <h1 className="text-blue text-lg font-montserrat font-bold text-center mb-5" data-testid="Add529FundsAccountDetails-heading">{details.heading}</h1>

        <When condition={step.list && !!step.list.length}>
          <Stepper
            steps={step.list}
            current={step.stepNumber}
            className="mb-10"
          />
        </When>

        <p className="text-base font-bold font-montserrat text-indigo" data-testid="Add529FundsAccountDetails-description">
          {details.description}
        </p>
      </header>

      <main className="modal-screen-footer-fixed px-12 modal-body-scroll">
        <DirectDebitAccountForm
          type="fidelity"
          formId={formId}
          ref={formRef}
          onIsValidForm={(isValid) => handleIsValidForm(isValid)}
          onPendingSubmit={(enable) => setIsPendingSubmit(enable)}
          onFormSuccess={(accountDetails) => handleOnFormSuccess(accountDetails)}
        />

        <div className="w-full shrink-0">
          <Button
            rounded
            fullWidth
            type="submit"
            form={formId}
            data-testid="Add529FundsAccountDetails-continue-btn"
            disabled={!isValidForm || isPendingSubmit}
            onClick={handleFormSubmit}
          >
            {isPendingSubmit
              ? <Spinner color="white" />
              : "Continue"
            }
          </Button>
        </div>
      </main>
    </>
  );
};

export default Add529FundsAccountDetails;