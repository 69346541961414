import { State529ProviderType } from "@lib/enums/state529";
import { ProfileType } from "@lib/enums/userProfile";
import type { UserProfiles } from "@lib/types/userProfile";

export const checkProfileStatus = (type: ProfileType, profile: UserProfiles) => {
  if (type === ProfileType.ONBOARDING) {
    const {
      hasBeneficiary,
      hasInstitutionPayments,
      hasTransactions,
      hasLinked529,
      planProviderType
    } = profile;

    // if a user has linked their 529 or has transactions
    // onboarding is complete
    let isOnboardingComplete = hasLinked529 || hasTransactions;

    if (hasInstitutionPayments
      && !hasTransactions
      && !hasLinked529
    ) {
      isOnboardingComplete = false;
    }

    // if a user has a direct debit 529 plan
    // if they have their linked their 529 but have not created an institution payment
    // onboarding is not complete
    if (planProviderType === State529ProviderType.DIRECT_DEBIT
      && hasLinked529
      && !hasInstitutionPayments
    ) {
      isOnboardingComplete = false;
    }

    return hasBeneficiary && isOnboardingComplete;
  }

  const isComplete = Object.values(profile).every((value) => !!value);

  return isComplete;
};