import studentsAtUni from "@assets/images/studentsAtUni.svg";

import Button from "@components/Button/Button";

import { useOnboardingStore } from "@stores/onboardingStore";

const ConfirmAttendanceScreen = () => {
  const goToPayTuition = useOnboardingStore((state) => state.goToPayTuition);
  const skipTuitionPayment = useOnboardingStore((state) => state.skipTuitionPayment);

  return (
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title w-8/10 sm:self-start" data-testid="onboarding-screen-header">Do you have a Tuition bill to pay?</h2>

        <img
          src={studentsAtUni}
          alt="students chatting infront of university"
          loading="lazy"
          height={262}
          width={272}
          className="h-auto w-full max-w-60 sm:max-w-[300] mt-10 sm:mt-16"
        />
      </div>

      <div className="w-full shrink-0 flex justify-between gap-4">
        <Button
          secondary
          rounded
          fullWidth
          data-testid="onboarding-skiptuition-button"
          onClick={skipTuitionPayment}
        >
          No, not yet
        </Button>

        <Button
          rounded
          fullWidth
          data-testid="onboarding-nextstep-button"
          onClick={goToPayTuition}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default ConfirmAttendanceScreen;