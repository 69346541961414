import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";

import Button from "@components/Button/Button";
import DescriptionList from "@components/DescriptionList/DescriptionList";

import { useTuitionPaymentsStore } from "@stores/tuitionPaymentStore";

import { formatAmountInCentsToCurrency } from "@utils/formatAmounts";
import { formatDateStringToShortMonth } from "@utils/formatDates";
import { useOnboardingStore } from "@stores/onboardingStore";

const TuitionPaymentInitiatedScreen = () => {
  const paymentConfirmation = useTuitionPaymentsStore(useShallow((state) => state.tuitionPaymentConfirmation));
  const goToNextStep = useOnboardingStore((state) => state.goToFinalScreenForDirectDebit);

  const confirmationDetails = useMemo(() => {
    if (!paymentConfirmation) return [];

    return [{
      term: "Payment Confirmation #",
      details: paymentConfirmation.withdrawal_request_id
    },
    {
      term: "Amount",
      details: formatAmountInCentsToCurrency(paymentConfirmation.amount_in_cents, true)
    },
    {
      term: "Date Initiated",
      details: formatDateStringToShortMonth(new Date().toISOString())
    }];
  }, [paymentConfirmation]);

  return (
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title">Payment initiated</h2>

        <p className="modal-header-description">Thank you for your payment. We&apos;ve initiated a debit from your Fidelity 529 Plan. The funds should arrive in 2-3 business days.</p>

        <div className="w-full mt-10">
          <DescriptionList list={confirmationDetails} listItemClassName="py-3.5" />
        </div>
      </div>

      <div className="button-container-full-width">
        <Button
          rounded
          fullWidth
          onClick={() => goToNextStep(true)}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default TuitionPaymentInitiatedScreen;