import { useMemo } from "react";
import { classnames } from "@utils/classnames";

export type StepperProps = {
  steps: string[];
  current: number;
  className?: string;
};

const Stepper = ({
  current,
  steps,
  className
}: StepperProps) => {
  const containerClassname = useMemo(() => {
    return classnames("w-full flex items-center justify-center gap-0.5", className);
  }, [className]);

  return (
    <ol className={containerClassname} data-testid="stepper-component">
      {steps.map((step, index) => (
        <li
          key={`${step}${index}`}
          className={classnames("w-14 h-2.5 rounded-md", [
            index + 1 > current ? "bg-gray-300" : "bg-blue",
          ])}
        />
      ))}
    </ol>
  );
};

export default Stepper;
