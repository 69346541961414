import { Suspense, useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";
import { When } from "react-if";

import Button from "@components/Button/Button";
import DescriptionList from "@components/DescriptionList/DescriptionList";
import Spinner from "@components/Spinner/Spinner";
import Stepper from "@components/Stepper/Stepper";

import { DEFAULT_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import { getState529Counterparty } from "@lib/queries/state529Queries";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { submitDebitWithdrawalRequest } from "@lib/mutations/state529Mutations";

import { useAddFundsStore } from "@stores/addFundsStore";

import { ADD_FUNDS_STEP_DETAILS } from "@lib/constants/addFunds";
import { State529ProviderType } from "@lib/enums/state529";
import { AddFundsSteps } from "@lib/enums/flows";

import { formatAmountToCents } from "@utils/formatAmounts";

const Add529FundsSubmit = () => {
  const step = useAddFundsStore(useShallow((state) => state.step));
  const beneficiary = useAddFundsStore(useShallow((state) => state.beneficiary));
  const payload = useAddFundsStore(useShallow((state) => state.payload));

  const beneficiary_id = useMemo(() => {
    if (!beneficiary) return "";

    return beneficiary.id;
  }, [beneficiary]);

  const state_529_plan_id = useMemo(() => {
    if (!beneficiary) return "";

    return beneficiary.state_529_plan_id;
  }, [beneficiary]);

  const setConfirmationDetails = useAddFundsStore((state) => state.setConfirmationDetails);
  const goToNextStep = useAddFundsStore((state) => state.goToNextStep);

  const {
    data: counterparty,
    isLoading: isCounterpartyLoading
  } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_COUNTERPARTY, state_529_plan_id, beneficiary_id],
    queryFn: () => getState529Counterparty(state_529_plan_id, beneficiary_id),
    enabled: !!state_529_plan_id.length && !!beneficiary_id.length,
    ...DEFAULT_QUERY_OPTIONS,
    throwOnError: true,
  });

  const details = useMemo(() => {
    if (!step.details) return ADD_FUNDS_STEP_DETAILS[State529ProviderType.DIRECT_DEBIT][AddFundsSteps.SUBMIT];

    return step.details[AddFundsSteps.SUBMIT];
  }, [step]);

  const withdrawalDetails = useMemo(() => {
    if (!counterparty || !payload.amount) return [];

    return [{
      term: "From",
      details: `${counterparty.state_529_plan.name}`,
    },
    {
      term: "Account",
      details: `**${counterparty.account_number_last_four}`,
    },
    {
      term: "Amount",
      details: `$${payload.amount}`
    }];
  }, [counterparty, payload]);

  const {
    mutate: submitDebitRequest,
    isPending: isSubmitDebitRequestPending,
  } = useMutation({
    mutationKey: [MUTATION_KEY.DEBIT_WITHDRAWAL_REQUEST],
    mutationFn: submitDebitWithdrawalRequest,
    throwOnError: true,
    onSuccess: async (results) => {
      setConfirmationDetails(results);
      goToNextStep();
    }
  });

  return (
    <Suspense fallback={<Spinner size="lg" />}>
      <header className="modal-content-fixed flex-col px-12 items-center">
        <h1 className="text-blue text-lg font-montserrat font-bold text-center mb-5" data-testid="Add529FundsSubmit-heading">Submit Withdrawal</h1>

        <When condition={step.list && !!step.list.length}>
          <Stepper
            steps={step.list}
            current={step.stepNumber}
            className="mb-10"
          />
        </When>

        <p className="text-base font-bold font-montserrat text-indigo text-center max-w-72" data-testid="Add529FundsSubmit-description">
          <span className="block text-xl mb-4">{details.subHeading}</span>
          {details.description}
        </p>
      </header>

      <main className="modal-screen-footer-fixed px-12 modal-body-scroll">
        <DescriptionList
          data-testid="Add529FundsSubmit-details"
          list={withdrawalDetails}
          containerClassName="mt-14"
          listItemClassName="py-3.5 gap-x-14"
        />

        <div className="w-full shrink-0">
          <span className="block text-xxxs text-gray-400 mb-4">By clicking “submit”, I authorize Backpack to initiate a withdrawal from my linked 529 Account</span>

          <Button
            rounded
            fullWidth
            data-testid="Add529FundsSubmit-submit-btn"
            disabled={isCounterpartyLoading || isSubmitDebitRequestPending}
            onClick={() => submitDebitRequest({
              state_529_plan_id: state_529_plan_id,
              payload: {
                beneficiary_id: beneficiary_id,
                amount_in_cents: formatAmountToCents(payload.amount),
              }
            })}
          >
            {isSubmitDebitRequestPending
              ? <Spinner color="white" />
              : "Submit"
            }
          </Button>
        </div>
      </main>
    </Suspense>
  );
};

export default Add529FundsSubmit;