import data from "../../data/529SupportArticleMapping.json";

import { State529Provider, State529ProviderType } from "@lib/enums/state529";
import type {
  ManualLinkType,
  State529Connection,
  State529Plan
} from "@lib/types/state529";
import type { OnboardingProfile } from "@lib/types/userProfile";

/**
 * Represents the details for what is returned in the support article mapping JSON for state 529 plans that require manual linking
 *
 * @property {ManualLinkType | string} verification_type - the manual plan's verification type i.e., 15_day_hold_manual, bank_forms, plaid_link, etc
 * @property {State529Plan["name"]} plan_name - State 529 plan name
 * @property {string} linking_guide - State 529 plan's support article url for linking 529
 * @property {string} withdrawal_guide - State 529 plan's support article url for withdrawal from 529
 */
type State529SupportDetail = {
  "verification_type": ManualLinkType | string;
  "plan_name": State529Plan["name"];
  "linking_guide": string;
  "withdrawal_guide": string;
}

/**
 * Type for the State 529 support article map
 *
 * @property {State529Plan["id"]} [key] - The ID of the 529 plan.
 * @property {State529SupportDetail} value - The support article details for the 529 plan.
 */
export type State529ArticleMap = {
  [key: State529Plan["id"]]: State529SupportDetail;
}

/**
 * Returns the state 529 plan's support article links for plans that require manual linking
 *
 * This function uses environment-specific data (production or development) to return the corresponding support details for the given 529 plan ID.
 * If the plan ID is not provided or does not exist in the mapping, `null` is returned.
 *
 * @param {State529Plan["id"]} state_529_plan_id - The ID of the 529 plan to retrieve support details for.
 * @returns {State529SupportDetail | null} The support details for the specified 529 plan, or `null` if not found.
 *
 */
export const getState529SupportDetails = (state_529_plan_id: State529Plan["id"]) => {
  if (!state_529_plan_id) return null;

  const articleMapping: State529ArticleMap = import.meta.env.VITE_APP_ENV === "production"
    ? data.production
    : data.development;

  return articleMapping[state_529_plan_id] || null;
};

/**
 * Retrieves the state 529's plan provider type and plan type based on the state 529 plan id and that state 529 plan's connection information
 *
 * @param {Object} params - Parameters for retrieving 529 plan details.
 * @param {State529Plan["id"]} params.state_529_plan_id - The ID of the 529 plan.
 * @param {State529Connection} params.state_529_connection - The connection details of the 529 plan.
 * @returns {Object} An object containing the 529 plan provider type and plan type.
 * @returns {State529ProviderType} return.planProviderType - The type of 529 plan provider (e.g., Automated, Manual, Direct Debit).
 * @returns {OnboardingProfile["planType"]} return.planType - The specific plan type (ManualLinkType or Fidelity) or `null` if provider type is Automated.
 *
 */
export const getState529PlanType = ({
  state_529_plan_id,
  state_529_connection
}: {
  state_529_plan_id?: State529Plan["id"];
  state_529_connection: State529Connection;
}) => {
  const {
    is_automated_withdrawal_eligible,
    provider_name,
  } = state_529_connection;

  // default to manual 529 plan provider type and plan type
  let planProviderType: State529ProviderType = State529ProviderType.MANUAL;
  let planType:OnboardingProfile["planType"] = state_529_plan_id
    ? getState529SupportDetails(state_529_plan_id)?.verification_type as OnboardingProfile["planType"]
    : null;

  // automated withdrawal eligible plans aka Ascensus 529 plans
  if (is_automated_withdrawal_eligible && provider_name === State529Provider.ASCENSUS) {
    planProviderType = State529ProviderType.AUTOMATED;
    planType = null;
  }

  // if provider_name is Fidelity
  if (provider_name === State529Provider.FIDELITY) {
    planProviderType = State529ProviderType.DIRECT_DEBIT;
    planType = State529Provider.FIDELITY;
  }

  return {
    planProviderType,
    planType
  };
};