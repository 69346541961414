import { useMemo } from "react";
import { classnames } from "@utils/classnames";

export const INPUT_TYPE = {
  TEXT: "InputText",
  AUTOCOMPLETE: "InputAutocomplete",
  SELECT: "InputSelect",
};

type InputType = typeof INPUT_TYPE[keyof typeof INPUT_TYPE];

type UseInputAttributesParams = {
  id: string;
  type: InputType;
  error?: string;
  helper?: string;
  hideLabel?: boolean;
};

export const useInputAttributes = ({
  id,
  error,
  helper,
  hideLabel,
  type = INPUT_TYPE.TEXT
}: UseInputAttributesParams) => {
  const labelClassName = useMemo(() => {
    return classnames("input-label", hideLabel && "sr-only");
  }, [hideLabel]);

  const inputClassName = useMemo(() => {
    return classnames({
      "input": type === INPUT_TYPE.TEXT,
      "input-autocomplete": type === INPUT_TYPE.AUTOCOMPLETE,
      "input-select": type === INPUT_TYPE.SELECT
    }, !!error && "error");
  }, [error]);

  const { ariaDescribedBy, description } = useMemo(() => {
    let ariaDescribedBy = undefined;
    let description = "";

    if (helper) {
      ariaDescribedBy = `${id}-description`;
      description = helper;
    }

    if (error) {
      ariaDescribedBy = `${id}-error`;
      description = error;
    }

    return { ariaDescribedBy, description };
  }, [id, helper, error]);

  return {
    labelClassName,
    inputClassName,
    ariaDescribedBy,
    description,
  };
};